@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    /* --bg-body: rgba(255, 255, 255);
    --bg-body-hover: rgb(214, 214, 214);
    --bg-nav: rgb(255, 255, 255);

    --clr-main: #00BFA5;
    --clr-main-hover: #00d3b7;
    --clr-sec: #222;
    --clr-sec-hover: #3d3d3d;
    --clr-tert: #FF8E53;

    --text-main: #222;
    --text-main-hover: #5e5e5e;

    --text-sec: rgb(114, 114, 114);
    --text-sec-hover: rgb(126, 126, 126);

    --fs-1: 28px;
    --fs-2: 24px;
    --fs-3: 18px;
    --fs-4: 16px;
    --fs-5: 14px;
    --fs-6: 8px;

    --clr-token: #ebc90c; */




    --size-ul: 40px;
    --size-xxl: 36px;
    --size-xl: 30px;
    --size-l: 26px;
    --size-m: 23px;
    --size-s: 18px;
    --size-xs: 15px;
    --size-xxs: 14px;
    --size-us: 12px;
    --size-uus: 10px;
    /*ultra small*/

    --color-primary: #ffffff;
    --color-primary-lightened: #e7e7e7;
    --color-secondary: #00dabc;
    --color-secondary-darkened: #00b89f;
    --color-secondary-lightened: #00e6c7;
    --color-dark: #222;
    --color-dark-lightened: #505050;
    --color-light: #6b6b6b;
    --color-lighter: #e2e2e2;
    --color-lighter-lightened: #dddddd;
    --color-lightest: #f3f3f3;
    --color-link: #007cc1;
    --color-link-lightened: #0092e0;
    --color-golden: #ebc90c;
    --color-golden-lightened: #ffde21;
    --color-success: #02dd70;
    --color-danger: #ec2308;
    --color-danger-lightened: #fa2b10;
    --color-glass: #2222221f;
    --color-glass-lightened: #6d6d6d1f;
    --color-response-highlight: #2d7c02;
    --color-response-highlight-lightened: #5a9739da;

    --color-message-bg: #d1e6c6;
    --color-message-actions-bg: #ffffff81;
    --color-response-bg: var(--color-lightest);
    --color-response-actions-bg: #22222200;

    --color-template-icon-1: #00DABC;
    --color-template-icon-2: #0DC8AE;
    --color-template-icon-3: #86E8DA;
    --color-template-icon-4: #039A85;

    --color-code-bg: #e9e9e9;
    --color-code-border: #e2e2e2;
    --color-code-text-default: #2d2d2d;
    --color-code-comment: hsl(150, 74%, 37%);
    --color-code-meta: hsl(150, 74%, 37%);
    --color-code-built-in: #e9950c;
    --color-code-keyword: #2e95d3;
    --color-code-string: #00a67d;
    --color-code-variable: #df3079;
    --color-code-bullet: #f22c3d;

    --nav-height: 5rem;
    --footer-height: 5rem;
}

@media screen and (max-width: 640px) {
    :root {
        --size-ul: 30px;
        --size-xxl: 28px;
        --size-xl: 24px;
        --size-l: 21px;
        --size-m: 19px;
        --size-s: 15px;
        --size-xs: 11px;
        --size-xxs: 10px;
        --size-us: 9px;

        --footer-height: 6rem;
    }
}