@import url("./base.css");

.saved-prompts {
  margin: 7vh 0;
}

.saved-prompts-list {
  margin: 1rem 0;
}

.saved-prompt-list-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  white-space: pre-wrap;
}

.saved-prompt-left {
  width: 80%;
}

.saved-prompt-right {
  width: 20%;
}

.saved-prompt-list-item {
  padding: 1.5rem 1.5rem;
  border-radius: 10px;
  transition: all 150ms linear;
  background-color: var(--color-lightest);
  margin-bottom: 1rem;
}

.saved-prompt-list-item:hover {
  background-color: var(--color-lighter);
}

.deleting-saved-prompt {
  background-color: rgba(255, 0, 0, 0.281);
  transition: all 150ms linear;
  position: relative;
  overflow-x: hidden;
}

.deleting-saved-prompt::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 30%;
  background: rgba(255, 255, 255, 0.116);
  top: 0;
  left: -100%;
  transform: skew(-20deg);
  animation: loadingAnimation 500ms linear infinite;
}

@keyframes loadingAnimation {
  0% {
    left: -100%;
  }

  100% {
    left: 110%;
  }
}

.deleting-saved-prompt:hover {
  background-color: rgba(255, 0, 0, 0.349);
}

.saved-prompt {
  font-size: var(--size-xs);
  padding: 0;
  margin: 0;
  padding-right: 10px;
  word-wrap: break-word !important;
  word-break: break-all !important;
  overflow-x: hidden;
}

@media screen and (max-width: 786px) {
  .saved-prompts-list.container {
    padding: 0 !important;
  }

  .saved-prompt-list-item {
    flex-direction: column-reverse;
    padding-top: 10px;
  }

  .saved-prompt-left {
    width: 100%;
    padding-top: 10px;
  }

  .saved-prompt-right {
    width: 100%;
  }

  .saved-prompt-right .edit-btn-container {
    gap: 0;
  }


  .saved-prompt-right .edit-btn-container {
    flex-wrap: wrap;

  }

}