@import url("./base.css");

.template-container.center {
  justify-content: center;
  align-items: center;
}

.template-container {
  min-height: 30dvh;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}

.template-container .marketplace-template {
  width: 48%;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px;
  margin: 1%;
  position: relative;
  display: flex;
  /* Use flex display */
  flex-direction: column;
  /* Stack child elements */
}

.template-content {
  flex-grow: 1;
  overflow: hidden;
}

.marketplace-template .progress-container {
  position: relative;
  width: 99.5%;
  height: 5px;
  top: 0;
  margin-left: 1px;
  border-radius: 100px 100px 0 0;
  overflow: hidden;
}

.marketplace-template .progress-container .my-progress.on {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 20px;
  transition: all 100ms linear;
  animation: progress-loading-animation 1s linear infinite;
  background-image: linear-gradient(to right, rgb(0, 102, 255) 50%, transparent 50%);
  background-size: 200% 100%;
}

@keyframes progress-loading-animation {
  0% {
    background-position: 200% 0;
  }

  50% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.button-favourite {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: var(--size-xxl);
  height: var(--size-xxl);
}

.button-favourite svg {
  width: calc(var(--size-xxl) - var(--size-xxs));
  height: calc(var(--size-xxl) - var(--size-xxs));
}

.button-favourite .not-favourite-icon {
  fill: var(--color-dark);
}

.button-favourite .favourite-icon {
  fill: var(--color-golden);
}

.button-favourite.toggling {
  overflow: hidden;
}

.button-favourite.toggling::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  z-index: 1;
  background-color: #ffffff80;
  left: 0;
  top: 0;
  transform: rotate(30deg);
  animation: loading-animation 800ms infinite;
}

@keyframes loading-animation {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: 80%;
    opacity: 0;
  }

  100% {
    opacity: 0;
    left: 0%;
  }
}


.button-favourite-filter-toggle:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.marketplace-template .template-body {
  padding: var(--size-xl);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.marketplace-template .template-name {
  font-size: var(--size-s);
  color: var(--color-dark);
  font-weight: 500;
  position: relative !important;
  cursor: pointer;
  text-decoration: none;
  margin-top: 1.5rem;
  display: block;
}

.marketplace-template .template-name.hover-underline:hover {
  text-decoration: underline;
}

.marketplace-template .template-name .installed-tag {
  font-size: var(--size-xxs) !important;
  position: absolute;
  top: -1.5rem;
  left: 0;
}

.marketplace-template .company-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 5px 0;
  font-size: var(--size-xxs) !important;
}

.marketplace-template .company-container .company-name {
  font-size: var(--size-xxs) !important;
  margin: 0;
  color: rgb(56, 56, 56);
}

.marketplace-template .company-container .company-name a {
  color: rgb(56, 56, 56);
}


.marketplace-template .template-description {
  font-size: var(--size-xxs);
  color: var(--color-light);
  margin: 1rem 0;
}

.template-categories {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.template-categories .category {
  font-size: var(--size-xxs);
  text-transform: capitalize;
  cursor: pointer;
  transition: all 100ms linear;
  background-color: #00aeff28;
  color: #004464;
  padding: 4px var(--size-xxs);
  border-radius: 5px;
  margin: 4px;
}

.marketplace-template .template-categories .category:hover {
  color: #00405e;
  background-color: #00aeff41;

}

.marketplace-template .template-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: var(--size-xxl);
}

.applied-filters {
  /* min-height: 3rem; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--size-xs);
  margin-bottom: 2rem;
}

.applied-filters .tag {
  text-transform: capitalize;
}

.tag-search {
  background-color: rgba(93, 178, 248, 0.329);
  color: rgb(0, 76, 138);
}

.applied-filters .filter {}


.filter-categories {
  height: 30dvh;
  overflow-y: scroll;
  padding: 3dvh 0;
}

.filter-categories .filter-categories-category {
  cursor: pointer;
  transition: all 100ms linear;
  display: inline-flex;
  margin: 7px;
}

.filter-categories .filter-categories-category:hover {
  text-decoration: underline;
  color: #131313 !important;
}

.dropdown-company-element {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.dropdown-company-element .company-detail {
  padding: var(--size-xs);
  padding-right: 0;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-company-element .company-detail div {
  border-right: 1px solid rgb(209, 209, 209);
  width: 100%;
  padding-right: var(--size-xs);
  margin: 0;
}

.dropdown-company-element .company-detail span {
  font-size: var(--size-xxs);
}

.dropdown-company-element .company-detail:hover {
  color: var(--color-primary);
  background-color: var(--color-dark);
}

.dropdown-company-element .company-detail:hover div {
  border: none;
}

.dropdown-company-element .company-detail:hover span {
  color: #cacaca;
}

.dropdown-company-element .button-container {
  padding: 0 var(--fs-6);
}

.dropdown-company-element .button-container svg {
  font-size: var(--fs-3) !important;
  fill: rgb(49, 49, 49);
}

.mobile-signup-container {
  display: none;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin: 1.5rem 0 0.5rem 0;
}

.mobile-signup-container span {
  display: flex;
  height: 1.5rem;
  width: 1px;
  background-color: #888888;
}

.searchable-dropdown {
  display: none;

  position: absolute;
  z-index: 2;
  right: 0;
  top: 90%;
  background-color: var(--color-primary);
  width: 20rem;
  border: 1px solid var(--color-lighter);
  padding: var(--size-s);
  padding-top: 0;
  border-radius: 5px;
  height: 40dvh;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  overflow-y: auto;
}

.searchable-dropdown .dropdown-content {
  position: relative;
}

.searchable-dropdown .dropdown-header {
  padding-top: var(--size-s);
  position: sticky;
  top: 0;
  left: 0;
  background-color: var(--color-primary);
  padding-bottom: var(--size-xs);
  border-bottom: 1px solid var(--color-lighter);
}

.searchable-dropdown.dropdown-active {
  display: flex;
  flex-direction: column;
}


.searchable-dropdown input {
  font-size: var(--size-xxs) !important;
}

.searchable-dropdown .category-list {
  padding-top: var(--size-xs);
  display: flex;
}

.category-list .dropdown-categories {
  max-height: 100%;
  display: flex;
  gap: var(--size-xxs);
  flex-wrap: wrap;
  overflow-y: scroll;
  padding-bottom: 10px;
  padding-right: 3px;
  height: fit-content;

}

.searchable-dropdown .category-list .filter-categories-category {
  width: fit-content;
  cursor: pointer;
  height: fit-content;
}

.searchable-dropdown .category-list .filter-categories-category:hover {
  text-decoration: underline;
}

.dropdown-title {
  text-align: left;
  margin-bottom: 1rem;
}

.dropdown-title p {
  margin: 0;
  font-weight: 500;
  font-size: var(--size-xxs) !important;
}



.custom-tabs {
  display: flex;
  gap: 5px;
  position: relative;
  border: 1px solid var(--color-lighter);
  border-radius: 20px;
  padding: 5px;
}

.custom-tabs .tab-button {
  background-color: transparent;
  border: none;
  font-size: var(--size-xs);
  padding: calc(var(--size-xs) / 2) var(--size-l);
  transition: all 0.3s linear;
}

.custom-tabs .tab-button:hover {
  opacity: 80%;
}

.custom-tabs .tab-button.active-tab {
  color: var(--color-primary);
}

.custom-tabs #active-tab-background {
  padding: calc(var(--size-xs) / 2) var(--size-l);
  position: absolute;
  height: calc(100% - 10px);
  background: var(--color-dark);
  border-radius: 20px;
  /* Change this to your preferred background color */
  transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
  z-index: -1;
}


@media screen and (max-width: 786px) {
  .template-container .marketplace-template {
    width: 100%;
    margin-bottom: 1rem;
  }

  .template-container .marketplace-template .template-body {
    padding: var(--size-s) var(--size-l);
  }

  .template-container .marketplace-template .template-footer {
    margin-top: var(--size-s);
  }

  .mobile-signup-container {
    display: flex;
  }


  .category-select-dropdown.dropdown-active::before {
    content: "";
    background-color: #22222249;
    width: 100vw;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6 !important;
  }

  .searchable-dropdown {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    top: unset;
    right: unset;
    width: 100vw;
    border-radius: 10px 10px 0 0;
    z-index: 7;
    transform: translateY(100%);
    transition: all 200ms linear;
    height: auto !important;
    max-height: 70dvh;
    padding-top: var(--size-s);
  }

  .searchable-dropdown::before {
    content: "";
    display: flex;
    width: 2.5rem;
    height: 5px;
    background-color: var(--color-lighter);
    border-radius: 10px;
    margin: auto;
    margin-bottom: 2rem;
  }

  .searchable-dropdown .dropdown-header {
    position: relative;
    padding-top: 0;
    padding-bottom: var(--size-s);
    border-bottom: 1px solid var(--color-lighter);
  }

  .searchable-dropdown.dropdown-active {
    transform: translateY(0%);
  }


  .dropdown-title {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .dropdown-title p {
    margin: 0;
    font-weight: 500;
    font-size: var(--size-s) !important;
  }

  .category-list {
    padding-right: 10px;
    padding-top: 20px !important;
    padding-bottom: 40px !important;
    max-height: 45dvh !important;
    overflow: scroll;
  }
}