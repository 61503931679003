@import url("./base.css");



/* ======  HEADINGS  ======= */
.section-title {
    font-family: 'Raleway', sans-serif;
}

.title {
    font-size: var(--size-xl)
}

p {
    font-size: var(--size-xs) !important;
}

div,
span {
    font-size: var(--size-xs);
}

.modal-title.h4 {
    font-size: var(--size-m) !important;
}

.flex-gap-3 {
    gap: var(--size-xxs);
}

/* ======  OTHERS  ======= */
.link {
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.7;
}

.disabled button {
    cursor: not-allowed !important;
    pointer-events: none;
}

.box {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-top: 4vh;
}

.subtitle {
    font-family: 'Raleway', sans-serif;
    color: var(--color-light);
}

.cursor-pointer {
    cursor: pointer;
}

section {
    margin: 3rem 0;
}

.flexed-container {
    /* border: solid; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 40%;
    min-width: 30rem;
}




/* ======  DROPDOWN LIST  ======= */
.list-dropdown {
    min-height: 3.5rem;
    height: auto;
    max-height: 20vh;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--color-primary);
    overflow-y: scroll;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border-radius: 0 0 5px 5px;
    z-index: 100;
    border: 1px solid rgb(218, 218, 218);
}

.list-dropdown .list-container .list-item {
    width: 100%;
}

.list-dropdown.md {
    max-height: 200%;
}

.list-dropdown .list-container {
    min-height: auto;
}

.list-dropdown.top-placed {
    border-radius: 5px 5px 0 0;
    top: unset;
    bottom: 100%;
    box-shadow: rgba(17, 12, 46, 0.15) 48px 0 100px 0px;
}

.list-item {
    padding: 1rem;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    border-bottom: 1px solid rgb(236, 236, 236);
    border-radius: 8px;
}

.list-item:hover {
    background-color: rgb(236, 236, 236);
    background-color: #222;
    color: #fff;
}

.list-item button:hover {
    background-color: var(--color-light);
}






/* ======  BUTTON  ======= */
.btn-container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.btn-row-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    padding: 0;
    flex-wrap: wrap;
}

.btn-row-container.left {
    justify-content: flex-start;
}

.btn-row-container.left button:nth-of-type(1) {
    /* margin-left: -1rem; */
}



.button {
    border: none;
    background-color: var(--color-secondary);
    border-radius: 40px;
    min-width: 8rem;
    padding: 0 2rem;
    transition: all 100ms linear;

    font-size: var(--size-xs) !important;
    height: fit-content !important;
    padding-top: var(--size-us) !important;
    padding-bottom: var(--size-us) !important;
}

a.button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--color-dark);
    background-color: var(--color-secondary);
    padding: 0;
    padding: 0 1.5rem;
}

.button:hover {
    background-color: var(--color-secondary-lightened);
}

.button-new-chat {
    background-color: transparent !important;
    color: var(--color-secondary);
}

.button-new-chat:hover {
    background-color: var(--color-secondary-lightened);
    border-color: var(--color-secondary);
}

.button:disabled {
    cursor: not-allowed;
}

.button-transparent {
    border: none;
    padding: 0;
    background-color: transparent;
}

.edit-btn-container {
    display: flex;
    gap: 5%;
    justify-content: center;
    align-items: center;
}

.edit-btn {
    border: none;
    background-color: transparent;
    border-radius: 100%;
    width: 2.6rem;
    height: 2.6rem;
    transition: all 100ms linear;
    font-size: var(--size-xxs) !important;
    color: var(--color-dark);
}

.edit-btn:hover {
    background-color: rgba(128, 128, 128, 0.11);
}


.edit-btn.dark-btn:hover {
    background-color: rgba(255, 255, 255, 0.986);
}

.edit-icon {
    pointer-events: none !important;
}

.dark-icon {
    font-size: 1.4rem;
    fill: rgb(68, 68, 68);
}

.hover-spin {
    transition: all 100ms linear;
}

.hover-spin:hover {
    transform: rotate(45deg);
}

.button-plain-text {
    border: none;
    background-color: transparent;
    width: fit-content;
    /* color: rgb(0, 55, 173); */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    height: 2rem;
    color: var(--color-dark);
    margin: 0;
    border-radius: 20px;
    padding: var(--size-s) var(--size-s);
}

.button-plain-text:hover {
    color: var(--color-dark-lightened);
    background-color: var(--color-lighter-lightened);
}



.button-plain-text:disabled {
    color: rgb(94, 94, 94);
}

.button-link {
    border: none;
    padding: 0;
    background-color: transparent;
    display: inline-flex;
    color: rgb(0, 81, 255);
    transition: all 100ms linear;
    align-items: center;
    gap: 5px;
}

.button-link:hover {
    color: rgb(36, 106, 255);
}

.button-link:disabled {
    opacity: 60%;
}


.button-ghost {
    background-color: var(--color-dark) !important;
    color: var(--color-primary) !important;
}

.button-ghost:hover {
    background-color: var(--color-dark-lightened) !important;
}

.button-ghost:disabled {
    opacity: 0.7;
}

.button-light {
    background-color: transparent;
    border: 1px solid #a1a1a1;
    color: #5c5c5c;
}

.button-light:hover {
    background-color: transparent;
    border-color: #888888;
    color: #3d3d3d;
}

.button-light:disabled {
    border-color: #b4b4b4;
    color: #929292;
}

.button-light:disabled:hover {
    border-color: #b4b4b4;
    color: #929292;
}

.button-danger {
    background-color: var(--color-danger);
    color: var(--color-primary);
}

.button-danger:hover {
    background-color: var(--color-danger-lightened);
}

.button-danger:disabled {
    opacity: 0.7;
}

.button-border {
    background-color: transparent;
    border: 1px solid var(--color-dark);
    color: var(--color-dark);
}

.button-border:hover {
    background-color: transparent;
    border: 1px solid var(--color-dark-lightened);
    color: var(--color-dark-lightened);
}

.small-button {
    height: auto;
    width: auto !important;
    min-width: auto !important;
    padding: 0.5rem 1.5rem;
    background-color: var(--color-dark);
    color: var(--color-primary);
}

.small-button:hover {
    background-color: var(--color-dark);
}

.small-button:disabled {
    background-color: var(--color-dark-lightened);
}

.tooltip-button {
    background-color: transparent !important;
    width: auto !important;
    border: none !important;
}

.tooltip-button svg {
    margin-top: -5px;
}


.regenerate-button {
    height: 2.3rem;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.button-new-chat {
    color: var(--color-secondary) !important;
    background-color: transparent;
    border: 1px solid var(--color-secondary);
}

.button-new-chat:hover {
    color: var(--color-secondary-lightened) !important;
    border-color: var(--color-secondary-lightened);
    background-color: transparent;
}

.button-icon {
    all: unset;
    width: var(--size-xxl);
    height: var(--size-xxl);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 150ms ease-in-out;
}

.button-icon:hover {
    background-color: var(--color-glass);
}

.button-icon svg {
    font-size: var(--size-s);
    transition: all 150ms ease-in-out;
}

.overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
}

.centered-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.end-flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


.select-button-container {
    /* height: 2.6rem; */
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* gap: 1px; */
}

.select-button-container select {
    padding-right: 2rem;
    padding-left: 1rem;
    border-radius: 0 30px 30px 0;
    min-height: 2rem;
    /* padding-top: 0.6rem;
    padding-bottom: 0.6rem; */
    background-color: var(--color-dark);
    color: var(--color-primary);
    border: solid var(--color-dark);

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .5rem center;
    background-size: 1em;

    flex-grow: 1;
    margin: 1px !important;
}

.select-button-container select:hover {
    background-color: var(--color-dark-lightened);
    border-color: var(--color-dark-lightened);
}

.select-button-container select:focus {
    outline: none;
}

.select-button-container button {
    min-height: 2rem;
    padding-left: 2rem;
    padding-right: 1rem;
    padding-top: .6rem;
    padding-bottom: .6rem;
    border-radius: 30px 0 0 30px;
    border: none;
    background-color: var(--color-dark);
    color: var(--color-primary);
}

.select-button-container button:disabled {
    opacity: 0.8;
    cursor: not-allowed;
}

.select-button-container button:disabled+select {
    opacity: 0.8;
    cursor: not-allowed;
    pointer-events: none;
}

.select-button-container button:hover {
    background-color: var(--color-dark-lightened);
}

.select-button-container button:hover+select {
    background-color: var(--color-dark-lightened);
    border-color: var(--color-dark-lightened);
}

.icon-link {
    color: #0037ad;
    transition: color 100ms linear;
    /* text-decoration: none; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: var(--size-xs);
}

.icon-link:hover {
    color: #0045da;
}

.profile-image {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    object-fit: cover;
}

.overlay-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    background-color: #222222f6;
    color: var(--color-primary);
}

.edit-btn.dropdown-toggle {
    background-color: transparent !important;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.edit-btn.dropdown-toggle::after {
    display: none;
}

.edit-btn.dropdown-toggle svg {
    margin: 0;
    padding: 0;
    fill: #222;
    transition: fill 200ms linear;
}

.edit-btn.dropdown-toggle:hover {
    background-color: transparent !important;
}

.edit-btn.dropdown-toggle:hover svg {
    fill: #222222a9 !important;
}

.wrapped-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: .5rem;
}

.file-upload-link input {
    border: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    display: none;
    /* opacity: 0; */
    pointer-events: none;
}

.file-upload-link input:hover {
    border: none !important;
}

.file-upload-link label {
    height: 2rem;
    color: rgb(0, 60, 255);
    transition: color 100ms linear;
    cursor: pointer;
}

.file-upload-link label:hover {
    color: rgb(60, 106, 255);
}

/* .file-upload-link::before {
    content: 'upload email list(csv)';
    position: absolute;
    top: 0;
    left: 0;
} */

.dropdown button::after {
    bottom: 0;
}

.dropdown-item {
    cursor: pointer;
}

.dropdown-item:active {
    background-color: var(--color-dark) !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}


.loading-row {
    background-color: rgba(105, 105, 105, 0.226) !important;
    opacity: 0.8;
    position: relative;
    pointer-events: none;
}

.loading-row::after {
    content: "";
    position: absolute;
    top: 0;
    left: 80%;
    width: 10rem;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.411);
    transform: skew(30deg);
    animation: loading-row-animation 1s infinite;
}

@keyframes loading-row-animation {
    0% {
        left: 0;
        opacity: 1;
    }

    50% {
        left: 80%;
        opacity: 0;
    }

    100% {
        opacity: 0;
        left: 0%;
    }
}

.scroll-down-button {
    /* position: fixed;
    bottom: 4dvh;
    right: 4vw;
    background-color: var(--color-primary);
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    z-index: 20; */
    border: 1px solid var(--color-lighter);
}

.scroll-down-button svg {
    font-size: var(--size-m);
}

.toast-message {}

.toast-message a {
    color: var(--color-secondary) !important;
    text-decoration: underline !important;
    cursor: pointer !important;
}

.toast-message a:hover {
    color: var(--color-secondary-hover) !important;
}

.flexed-between {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.new-feature-container {
    position: relative;
}

.new-feature {
    background-color: red;
    color: var(--color-primary);
    padding: 4px 6px;
    border-radius: 10px;
    font-size: var(--size-xs);
}

.bg-sc-primary {
    background-color: var(--color-primary) !important;
}

.bg-dark {
    background-color: var(--color-dark);
}

.bg-dark-lightened {
    background-color: var(--color-dark-lightened);
}

.bg-light {
    background-color: var(--color-light);
}

.bg-lighter {
    background-color: var(--color-lighter);
}

.bg-lighter-lightened {
    background-color: var(--color-lighter-lightened);
}

.bg-lightest {
    background-color: var(--color-lightest);
}

.bg-glass {
    background-color: var(--color-glass);
}

.bg-glass-lightened {
    background-color: var(--color-glass-lightened);
}

.bg-template-icon-1 {
    background-color: var(--color-template-icon-4) !important;
}

.bg-template-icon-2 {
    background-color: var(--color-template-icon-4) !important;
}

.bg-template-icon-3 {
    background-color: var(--color-template-icon-4) !important;
}

.bg-template-icon-4 {
    background-color: var(--color-template-icon-4) !important;
}

.font-ul {
    font-size: var(--size-ul) !important;
}

.font-xxl {
    font-size: var(--size-xxl) !important;
}

.font-xl {
    font-size: var(--size-xl) !important;
}

.font-l {
    font-size: var(--size-l) !important;
}

.font-m {
    font-size: var(--size-m) !important;
}

.font-s {
    font-size: var(--size-s) !important;
}

.font-xs {
    font-size: var(--size-xs) !important;
}

.font-xxs {
    font-size: var(--size-xxs) !important;
}

.font-us {
    font-size: var(--size-us) !important;
}

.font-uus {
    font-size: var(--size-uus) !important;
}

.w-ul {
    width: var(--size-ul) !important;
}

.w-xxl {
    width: var(--size-xxl) !important;
}

.w-xl {
    width: var(--size-xl) !important;
}

.w-l {
    width: var(--size-l) !important;
}

.w-m {
    width: var(--size-m) !important;
}

.w-s {
    width: var(--size-s) !important;
}

.w-xs {
    width: var(--size-xs) !important;
}

.w-xxs {
    width: var(--size-xxs) !important;
}

.w-us {
    width: var(--size-us) !important;
}

.h-auto {
    height: auto;
}

.h-0 {
    height: 0;
}

.h-ul {
    height: var(--size-ul) !important;
}

.h-xxl {
    height: var(--size-xxl) !important;
}

.h-xl {
    height: var(--size-xl) !important;
}

.h-l {
    height: var(--size-l) !important;
}

.h-m {
    height: var(--size-m) !important;
}

.h-s {
    height: var(--size-s) !important;
}

.h-xs {
    height: var(--size-xs) !important;
}

.h-xxs {
    height: var(--size-xxs) !important;
}

.h-us {
    height: var(--size-us) !important;
}

.min-height {
    min-height: calc(95dvh - var(--footer-height) - var(--nav-height));
}

.color-primary {
    color: var(--color-primary) !important;
}

.color-primary-lightened {
    color: var(--color-primary-lightened) !important;
}

.color-secondary {
    color: var(--color-secondary) !important;
}

.color-secondary-darkened {
    color: var(--color-secondary-darkened) !important;
}

.color-secondary-lightened {
    color: var(--color-secondary-lightened) !important;
}

.color-dark {
    color: var(--color-dark) !important;
}

.color-dark-lightened {
    color: var(--color-dark-lightened) !important;
}

.color-light {
    color: var(--color-light) !important;
}

.color-lighter {
    color: var(--color-lighter) !important;
}

.color-lighter-lightened {
    color: var(--color-lighter-lightened) !important;
}

.color-lightest {
    color: var(--color-lightest) !important;
}

.color-link {
    color: var(--color-link) !important;
}

.color-link-lightened {
    color: var(--color-link-lightened) !important;
}

.color-response-highlight {
    color: var(--color-response-highlight) !important;
}
.color-response-highlight-lightened {
    color: var(--color-response-highlight-lightened) !important;
}

.color-template-icon-1 {
    color: var(--color-template-icon-4) !important;
}

.color-template-icon-2 {
    color: var(--color-template-icon-4) !important;
}

.color-template-icon-3 {
    color: var(--color-template-icon-4) !important;
}

.color-template-icon-4 {
    color: var(--color-template-icon-4) !important;
}

.background-secondary {
    background-color: var(--color-secondary) !important;
}

.background-dark {
    background-color: var(--color-dark) !important;
}

.background-light {
    background-color: var(--color-light) !important;
}

.background-lighter {
    background-color: var(--color-lighter) !important;
}

.transition-all {
    transition: all 150ms linear;
}

.rotate-0 {
    transform: rotate(0);
}

.rotate-180 {
    transform: rotate(180deg);
}

.disabled-input:disabled {
    background: var(--color-lighter);
    cursor: not-allowed;
}

/* Shining effect with glittering */
.shine-hover {
    position: relative;
    overflow: hidden;
    z-index: 0;
}

.shine-hover::before {
    content: "";
    position: absolute;
    width: 100%;
    aspect-ratio: 1;
    pointer-events: none;
    /* background: radial-gradient(circle, rgba(197, 34, 34, 0.6) 0%, rgba(0, 255, 115, 0) 70%); */
    background: radial-gradient(circle, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 70%);
    top: 100%;
    left: -100%;
    transition: all 500ms linear;
    border-radius: 100%;
}

.shine-hover:hover::before {
    top: -100%;
    left: 100%;
    animation: shine-glitter 500ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes shine-glitter {}

/* Animations */
.sc_animate_fade_in_up {
    transition: all;
    animation: fadeInUp 150ms ease-in-out;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: translateY(0);
    }
}


@media screen and (max-width: 786px) {
    .title {
        font-size: var(--size-l);
    }

    .button-new-chat {
        min-width: 6.5rem !important;
        font-size: var(--size-xs) !important;
    }

    .container.py-5 {
        padding-top: 0.8rem !important;
    }

    .container.pt-5 {
        padding-top: 0.8rem !important;
    }

    .button {
        /* font-size: var(--size-xxs) !important; */
        min-width: 6rem;
    }

    .dropdown-link button {
        font-size: var(--size-xs) !important;
    }

    .select-button-container select {
        font-size: 0.9rem !important;
    }

    .edit-btn {
        width: var(--size-xl);
        height: var(--size-xl);
    }

    .scroll-down-button {
    }
}

[data-tooltip] {
    position: relative;
    cursor: pointer;
}

[data-tooltip]::before {
    content: attr(data-tooltip);
    position: absolute;
    background-color: var(--color-dark);
    color: var(--color-primary);
    padding: 8px 14px;
    font-size: var(--size-us);
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    transition: all 250ms cubic-bezier(.62, .16, .83, .67);
    white-space: nowrap;
    z-index: 10;
}

/* Position: Top */
[data-tooltip][data-tooltip-position="top"]::before {
    bottom: 100%;
    /* Position above */
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 5px;
    /* Space between tooltip and element */
}

/* Position: Bottom */
[data-tooltip][data-tooltip-position="bottom"]::before {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5px;
}

/* Position: Left or Default */
[data-tooltip]::before,
[data-tooltip][data-tooltip-position="left"]::before {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 5px;
}

/* Position: Right */
[data-tooltip][data-tooltip-position="right"]::before {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 5px;
}

/* Hover State */
[data-tooltip]:hover::before {
    opacity: 1;
    visibility: visible;
}

[data-tooltip]:hover::before,
[data-tooltip][data-tooltip-position="left"]:hover::before {
    right: 105%;
}

[data-tooltip][data-tooltip-position="right"]:hover::before {
    left: 105%;
}

[data-tooltip][data-tooltip-position="top"]:hover::before {
    bottom: 105%;
}

[data-tooltip][data-tooltip-position="bottom"]:hover::before {
    top: 105%;
}
