@import url("./base.css");

footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  background-color: var(--color-dark);
  color: var(--color-primary);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  gap: var(--size-xl);
  padding: var(--size-l) 0 var(--size-l) 0;
  height: var(--footer-height);
}

footer .home-branding span {
  font-size: var(--size-s) !important;
  border-right: 1px solid var(--color-light);
  padding-right: var(--size-xl);
}

footer .home-branding img {
  width: var(--size-xl) !important;
  height: var(--size-xl) !important;
}

footer .footer-links {
  display: flex;
  gap: 1rem;
  font-size: var(--size-xs);
}

footer .footer-links a {
  color: var(--color-primary);
  text-decoration: none;
  font-size: var(--size-xs);
}

footer .footer-links a:hover {
  color: var(--color-primary-lightened);
  text-decoration: underline;
}

@media screen and (max-width: 786px) {
  footer {
    flex-direction: column-reverse;
    padding: 2dvh 0;
    gap: 10px;
    /* display: none; */
  }

  footer .home-branding span {
    border: none;
    padding: none;
  }

  footer .home-logo {
    width: 600px !important;
    height: 600px !important;
  }

  footer .home-branding span {
    font-size: var(--size-xs) !important;
  }

  footer .footer-links a {
    font-size: var(--size-xxs);
  }
}