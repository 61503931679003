.loading {
    background-color: var(--color-lighter);
    transition: all 150ms linear;
    position: relative;
    overflow-x: hidden;
    width: 100%;
    height: 3.5rem;
    border-radius: 10px;
}

.loading::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 30%;
    background: rgba(255, 255, 255, 0.801);
    top: 0;
    left: -100%;
    transform: skew(-20deg);
    filter: blur(30px);
    animation: loadingAnimation 800ms linear infinite;
}

@keyframes loadingAnimation {
    0% {
        left: -100%;
    }

    100% {
        left: 110%;
    }
}

.loading:hover {
    background-color: var(--color-lighter-lightened);
}

.loading.rectangle-half {
    width: 50%;
    height: 2rem;
    margin: 0.5rem 0;
}

.loading.circle {
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
}

.loading.square {
    width: 10rem;
    height: 10rem;
}

.loading.select {
    /* margin: 0.1rem 0; */
    height: 3.3rem;
}

.loading.tags {
    width: 8rem;
    height: 2.5rem;
    border-radius: 20px;
}

.loading.chat {
    width: 48%;
    height: 11rem;
    margin-bottom: 2rem;
}

.loading.saved-prompt {
    height: 5rem;
}

.loading.half {
    width: 50%;
}

.loading.button {
    width: 10rem;
    height: 3rem;
    border-radius: 30px;
}

.loading.tr {
    margin-top: 1rem;
    height: 2rem;
}

.loading.template {
    height: 4rem;
    margin-top: 1rem;
}

.loading.td {
    height: 1rem;
}

.loading.icon {
    height: calc(var(--size-xl) * 1.3);
    width: calc(var(--size-xl) * 1.3);
    border-radius: 100%;
}
.loading.highlighted-category {
    height: calc(var(--size-xxl) * 3);
    width: calc(var(--size-xxl) * 8);
    border-radius: 20px;
}
.loading.message-note-skeleton {
    background-color: var(--color-glass-lightened) !important;
}

@media screen and (max-width: 786px) {
    .loading.chat {
        width: 100% !important;
        height: 10rem;
        margin-bottom: .7rem;
    }

    .loading.rectangle {
        height: 3rem;
    }

    .loading.tags {
        width: 4rem;
        height: 2rem;
    }
}