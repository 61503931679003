@import url("./base.css");

.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.profile-picture {
    width: 20rem;
    height: 20rem;
    border-radius: 100%;
    background-position: center;
    background-size: cover;
    position: relative;
    cursor: pointer;
    transition: all 100ms ease-out;
}

.profile-picture::after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.137);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: all 100ms ease-in-out;
    opacity: 0;
}

.profile-picture:hover::after {
    opacity: 1;
}

.upload-new-profile-input {
    position: relative;
    color: white;
    border: none !important;
    outline: none !important;
    height: 3rem;
    margin: 0;
    padding: 0;
    width: 13rem;
}

.upload-new-profile-input:hover {
    border: none !important;
    outline: none !important;
}

.upload-new-profile-input:focus {
    border: none !important;
    outline: none !important;
}

.upload-new-profile-input::-webkit-file-upload-button {
    display: none;
}

.upload-new-profile-input::after {
    content: 'Change Profile Picture';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #222;
    cursor: pointer;
    color: rgb(0, 55, 173);
}

.upload-new-profile-input:hover::after {
    color: rgb(0, 69, 218);
}



/* form */
.update-profile-form-container {
    padding: 0 8vw;
}

.profile-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-lighter);
    border-radius: 5px;
    padding: 2rem;
    transition: all 100ms linear;
}

.profile-item.editing {
    gap: 2rem;
    height: 8rem;
    border: none;
    overflow: hidden;
}

.profile-item-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.profile-item-header .input-grp {
    width: 95%;
}

.profile-item-header .edit-btn-container {
    width: 5%;
    padding-top: 1rem;
}

.profile-item-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 6%;
}

.profile-item.textarea-item {
    height: 9.3rem;
    overflow: hidden;
    border: none;
}

.profile-item.editing.textarea-item {
    height: 14.5rem;
    border: 1px solid rgb(231, 231, 231);

}

.profile-item.password-item {
    height: 6rem;
    overflow: hidden;
    border: none;
}

.profile-item.editing.password-item {
    height: 23.5rem;
    border: 1px solid rgb(231, 231, 231);
    margin-top: 1rem;
}

.profile-item input.events-none,
.profile-item textarea.events-none {
    pointer-events: none;
}



/* ---  tabs  --- */
.profile-tabs {
    border: none !important;
    flex-wrap: nowrap !important;
    overflow-y: visible;
    overflow-x: auto;
    scrollbar-width: none;
    height: 4rem;
}

.profile-tabs>* {
    flex: 1;
    text-align: center;
    white-space: nowrap;
}

.profile-tabs .nav-link {
    color: var(--color-light);
    transition: all 150ms linear;
    outline: none !important;
    border: none !important;
    padding: 1rem var(--size-xxs) 0.6rem 0.2rem;
}

.profile-tabs .nav-link:hover {
    border: none !important;
    background-color: none !important;
    color: var(--color-dark);
    border-radius: none !important;
}

.profile-tabs .nav-link.active {
    border: none !important;
    position: relative;
    color: var(--color-dark) !important;
}

.profile-tabs .nav-link.active::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--color-secondary);
    border-radius: 5px;
}

.subscription-details-container {
    padding: 0 8vw;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.subscription-details-container .trial,
.subscription-details-container .cancelled {
    border: 1px solid #199400;
    color: #199400;
    margin: 0 1rem;
    padding: .3rem .6rem;
    border-radius: 20px;
}

.subscription-details-container .cancelled {
    color: #d60707;
    border-color: #d60707;
}


.subscription-details-container p {
    margin-bottom: 5px;
}


@media screen and (max-width: 800px) {
    .update-profile-form-container {
        padding: 0;
    }

    .profile-picture {
        width: 10rem;
        height: 10rem;
    }
}