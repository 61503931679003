@import url("./base.css");

.select-feature-heading {
  font-size: 1.1rem;
  font-weight: 500;
}

.api-feature {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: .5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.api-feature:hover {
  color: rgb(58, 58, 58);
}

.api-feature:hover input {
  border: 1px solid rgb(160, 160, 160);
}

.key-added-success {
  margin: 1rem 5.1vw;
  margin-bottom: 0;
  color: rgb(11, 172, 11);
}

.added-key-copy-container {
  display: flex;
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  justify-content: space-between;
  margin: 1rem 5vw;
  margin-top: 1rem;
  align-items: center;
  height: 4rem;
}

.added-key {
  padding: 0 1rem;
}

.added-key-copy {
  border-left: 1px solid #c5c5c5;
  padding: 0 .7rem;
}

.added-key-copy button {
  position: relative;
}

.added-key-copy button span {
  position: absolute;
  font-size: 14px;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.table-container {
  /* overflow-x: scroll; */
}

.api-keys-table {
  width: 100%;
  min-width: 60rem;
}

.api-keys-table thead th {
  padding: 1rem 0;
}

.api-keys-table tbody tr {
  border-top: 1px solid #b9b9b9;
}

.api-keys-table tbody tr td {
  padding: .7rem 0;
}

.flexed-cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.deleting-key {
  background-color: rgba(255, 0, 0, 0.226);
  opacity: 0.8;
  position: relative;
  pointer-events: none;
}

.deleting-key::after {
  content: "";
  position: absolute;
  top: 0;
  left: 80%;
  width: 10rem;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.178);
  transform: skew(30deg);
  animation: deleting-key-animation 1s infinite;
}

@keyframes deleting-key-animation {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: 80%;
    opacity: 0;
  }

  100% {
    opacity: 0;
    left: 0%;
  }
}

@media screen and (max-width: 786px) {}