@import url("./base.css");

.subscription-main {
  margin-top: 2rem;
}

.subscription-main h2 {
  text-align: center;
}

.subscription-main .subtitle {
  text-align: center;
}

.subscription-container {
  display: flex;
  gap: 5rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 4rem;
}

.subscription {
  width: 28rem;
  height: auto;
  min-height: 33rem;
  border-radius: 20px;
  padding: 1rem;
  background-color: var(--color-lightest);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.subscription-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid var(--color-lighter);
}

.subscription-header .right .name {
  font-size: var(--size-m) !important;
  font-weight: 500;
  margin: 0;
}

.subscription-header .price {
  font-size: var(--size-s) !important;
}

.subscription-body {
  padding: 2rem 1rem;
  height: auto;
  min-height: 20rem;
}

.features {
  height: auto;
  display: flex;
  flex-direction: column;
}

.feature {
  position: relative;
  margin-left: 2rem;
  font-size: var(--size-xs) !important;
}

.feature::before {
  content: '✓';
  position: absolute;
  left: -2rem;
  top: .3rem;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #f7f7f7;
  background-color: var(--color-secondary);
  border-radius: 100%;
}

.info-feature::before {
  content: 'i';
  font-style: italic;
  padding-top: 3px;
  background-color: rgb(39, 180, 236);
}

.subscription-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  padding-top: 2rem;
  border-top: 1px solid var(--color-lighter);
}

.sub-text {
  color: var(--color-light);
  font-size: .8rem;
}

.subscription button {
  background-color: #dbdbdb;
  width: 100%;
}

.subscription button:hover {
  background-color: #cacaca;
}

.recommended button {
  background-color: var(--color-secondary);
}

.recommended button:hover {
  background-color: var(--color-secondary-lightened);
}

@media screen and (max-width: 786px) {
  .subscription {
    width: 100%;
  }
}