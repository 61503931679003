@import url("./base.css");

.stat-container {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    margin: 6vh 0;
    justify-content: center;
}

.stat {
    width: 15rem;
    border-radius: 10px;
    background-color: var(--color-secondary);
    padding: 1.5rem;
    height: 8rem;
}

.stat-sec {
    background-color: var(--color-dark);
    color: var(--color-primary);
}

.stat-tert {
    background-color: var(--color-lighter);
}

.stat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.stat-header svg {
    font-size: var(--size-s);
}

.stat-header span {
    font-size: var(--size-xs);
    font-weight: 500;
}

.stat-body {
    display: flex;
}

.stat-body p {
    margin: 0;
    padding: 0;
    font-size: var(--size-xs);
}

.stat-body .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 1rem;
}


.user-stats-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.total-users-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.total-users::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 2rem;
    background-color: var(--color-light);
    bottom: -2rem;
    left: 50%;
    transform: translateX(-50%);
}

.total-users-container::after {
    content: "";
    position: absolute;
    width: 81%;
    height: 1px;
    background-color: var(--color-light);
    bottom: -13%;
    left: 50%;
    transform: translateX(-50%);
}

.total-users {
    position: relative;
    width: 13rem;
    height: 13rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--color-secondary);
    border: 5px solid var(--color-primary);
}


.total-users p {
    margin: 0;
    font-size: var(--size-xxl);
}

.total-users svg {
    position: absolute;
    top: -17px;
    font-size: var(--size-xxl);
    transition: all 100ms linear;
}

.total-users:hover .total-users svg {
    top: -20px;
}

.divided-users {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 4rem;
    position: relative;
    gap: 2vw;
}

.user-type {
    width: 12rem;
    width: 20vw;
    background-color: var(--color-dark);
    color: var(--color-primary);
    margin-right: -10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: .5rem;
}

.user-type::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 2rem;
    background-color: var(--color-light);
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
}


.user-type p {
    margin: 0;
    font-size: var(--size-xs) !important;
}

.user-type span {
    text-align: center;
}

.user-type .spinner-border {
    display: block;
    width: 1.3rem;
    height: 1.3rem;
    margin: .3rem 0;
}


.accept-icon {
    font-size: var(--fs-2);
    color: green;
}

.reject-icon {
    font-size: 1.5rem;
    color: red;
}


@media screen and (max-width: 500px) {
    .stat {
        width: 100%;
    }

    .total-users {
        width: 10rem;
        height: 10rem;
    }

    .total-users-container::after,
    .total-users::before,
    .user-type::before {
        display: none;
    }

    .divided-users {
        padding-top: 1rem;
        flex-direction: column;
        gap: 1rem;
    }

    .user-type {
        width: 100%;
    }
}