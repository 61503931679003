@import url("./base.css");

.ad-container {
  width: 100%;
  height: 40dvh;
  border-radius: 10px;
  overflow: hidden;
}

.ad-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 786px) {}