@import url("./base.css");

.table-container {
    width: 100%;
    /* overflow-x: auto; */
    /* overflow-y: visible; */
    scrollbar-track-color: red;
    /* scrollbar-width: 2rem !important; */
    margin-top: 1rem;
    padding-bottom: 1.5rem !important;
    font-size: var(--size-xs);
}

.table-container tr td {
    overflow: visible;
}

.table-container::-webkit-scrollbar {
    background: transparent;
    width: 4px;
    height: 0.7rem;
    margin-top: -1rem;
}

.table-container::-webkit-scrollbar-thumb {
    background: rgba(49, 49, 49, 0.349);
    border-radius: 10px;
}



.filter-icon {
    fill: rgba(235, 235, 235, 0.952);
    font-size: 1.1rem;
}

.my-table thead tr {
    border-radius: 10px !important;
    border: none;
    border-right: 1px solid;
    border-color: var(--color-primary);
    background-color: var(--color-dark) !important;
}

.my-table thead tr th {
    color: white;
    background-color: var(--color-dark) !important;
    font-weight: normal;
    padding: 1rem;
}

.my-table thead tr th:first-of-type {
    border-radius: 10px 0 0 0;
}


.my-table thead tr th:last-of-type {
    border-radius: 0 10px 0 0;
}

.status-indicator {
    position: relative;
    padding-left: 1rem;
}

.status-indicator::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: rgb(255, 36, 36);
    border-radius: 100%;
}

.status-indicator.active::before {
    background-color: rgb(0, 187, 56);
}

.templates-table td {
    vertical-align: top;
    padding: 1rem .5rem;
}

.templates-table thead tr th {
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(206, 206, 206);
}

.templates-table tbody tr {
    border-bottom: 1px solid rgb(206, 206, 206);
}

@media screen and (max-width: 425px) {
    .table-container {
        overflow-x: scroll;
    }
}