.tabs-container {
    position: relative;
    margin-bottom: 0;
}

.tabs {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    position: sticky;
    bottom: 5dvh;
    z-index: 1;
    background-color: var(--color-dark);
    width: fit-content;
    border-radius: 60px;
    padding: .2rem 4vw;
    transition: all 200ms linear;
}

.tabs.overlapped {
    /* bottom: calc(var(--footer-height) - 1px); */
    border-radius: 60px 60px 0 0;
    border-bottom: 1px solid rgb(51, 51, 51);
}

.tabs::-webkit-scrollbar {
    display: none
}


.tab {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 30px;
    padding: 10px 30px;
    gap: 3px;
    /* min-width: fit-content; */
    cursor: pointer;
    transition: all 100ms linear;
    font-size: var(--size-xxs);
    color: var(--color-primary);
    text-align: center;
}

.tab:hover {
    color: var(--color-primary-lightened);
}

.tab svg {
    font-size: var(--size-s);
}

.tab.active {
    color: var(--color-secondary);
}

.tab.active:hover {
    color: var(--color-secondary-lightened);
}

.tab:active svg {
    transition: all 200ms ease-in-out;
    transform: scale(120%);
    transform-origin: center;
}

.tabs-render-area {
    margin-top: -8dvh;
    min-height: calc(95dvh - var(--footer-height) - var(--nav-height));
}

.template-header .edit-btn svg {
    width: var(--size-xl);
    height: var(--size-xl);
}

@media screen and (max-width: 786px) {
    .tabs-container {
        /* padding-bottom: calc(var(--footer-height) + 12px); */
    }

    .tabs {
        margin: 0;
        bottom: 0;
        left: 0;
        margin: 0 calc(var(--bs-gutter-x) * -1);
        transform: none;
        border-radius: 5px 5px 0 0 !important;
        width: 100vw;
        padding: 15px 25px;

        justify-content: space-between;
        overflow: scroll;
        gap: var(--size-xxl);
    }

    .tab {
        padding: 0;
    }

    .tab svg {
        font-size: var(--size-xs);
    }
}