@import url('./base.css');


/* -------------------------------------------------------------------------- */
/* SwitchButton */
/* -------------------------------------------------------------------------- */
.switch-button {
    width: 3rem;
    height: 1.5rem;
    border: none;
    border-radius: 20px;
    background-color: var(--color-lighter);
    overflow: hidden;
    padding: 0 3px;
    transition: all 250ms linear;
    position: relative;
}
.switch-button:disabled {
    opacity: 60%;
}
.switch-button.switch-button-active {
    background-color: var(--color-dark);
}
.switch-slider {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 20px;
    background-color: #fff;
    transition: all 100ms linear;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
}
.switch-slider.switch-slider-active {
    left: 54%;
}
.switch-slider-loading {
    transition: all 100ms linear;
    animation: loadingAnimation 1s ease-in-out infinite;
}
@keyframes loadingAnimation {
    0% {
        background-color: #fff;
    }
    50% {
        background-color: #9c9c9c;
    }
    100% {
        background-color: #fff;
    }
}


/* -------------------------------------------------------------------------- */
/* HorizontalScroller */
/* -------------------------------------------------------------------------- */
.horizontal-scroller {
    position: relative;
    overflow: hidden;
    transition: all 1s linear;
}

/* Scroll container styles */
.scroll-container {
    overflow-x: auto;
    scrollbar-width: none; /* for Firefox */
    -ms-overflow-style: none; /* for IE and Edge */
}
.scroll-container::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

/* Scroll buttons */
.scroll-button {
    position: absolute;
    z-index: 2; /* Ensure they are above the gradient */
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--color-primary);
    color: var(--color-dark);
}
.scroll-button:hover {
    background-color: var(--color-glass-lightened);
}
.scroll-button.left {
    left: 5px;
}
.scroll-button.right {
    right: 5px;
}

/* Gradient overlay styles */
.horizontal-scroller::before,
.horizontal-scroller::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: calc(var(--size-ul) * 2); /* Adjust the width of the gradient as needed */
    pointer-events: none; /* Allow interactions with the content underneath */
    z-index: 1; /* Ensure they appear above the content but below the buttons */
    transition: opacity 150ms ease;
}

.horizontal-scroller::before {
    left: 0;
background: linear-gradient(to right, var(--color-primary) 40%, transparent);
    opacity: 0; /* Initially hidden */
}

.horizontal-scroller::after {
    right: 0;
background: linear-gradient(to left, var(--color-primary) 40%, transparent);
    opacity: 0; /* Initially hidden */
}

/* Show the gradients when there is more content to scroll */
.horizontal-scroller.has-left-scroll::before {
    opacity: 1;
}

.horizontal-scroller.has-right-scroll::after {
    opacity: 1;
}
