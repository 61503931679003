@import url("./base.css");

.prompt-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 4%;
  width: 100%;
  margin-top: 2.5rem;
}

.prompt-main {
  width: 48%;
  position: relative;
  transition: all 100ms linear;
  
}

.prompt-main .prompt {
  position: relative;
}

.prompt-main .prompt::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  transition: opacity 100ms ease-in-out;
}

.prompt-main .prompt:hover::after {
  opacity: 0.7;
}

.prompt-main .prompt:hover~.promp-main .prompt::after {
  border: solid;
}

.prompt-list-container .prompt-main .my-dropdown {
  width: 2.7rem;
  display: flex;
  position: absolute;
  background-color: var(--color-primary) !important;
  right: 1rem;
  top: 1.4rem;
}




/* .prompt-main button {
  position: absolute;
  right: 1rem;
  top: 1.4rem;
  z-index: 1;
} */

.prompt-list-container .prompt {
  min-height: 10rem;
  /* min-height: 16rem; */
  border-radius: 5px;
  padding: 2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
  transition: all 100ms linear;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.prompt-list-container.organization-chats .prompt,
.prompt-list-container.shared-chats .prompt {
  min-height: 11rem;
}

.prompt-list-container .prompt h4 {
  font-size: var(--size-xs);
  font-weight: 400;
  padding-right: 2rem;
  word-wrap: break-word;
}

.prompt-list-container .prompt .tags {
  color: var(--color-light);
  margin: 1rem 0;
  font-size: var(--size-xxs) !important;
  font-weight: 400;
}

.prompt .owner-info {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  /* margin-top: 1rem; */
  font-size: var(--size-xxs);
}


.no-chats {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  height: 35dvh;
}

.no-chats svg {
  font-size: 3rem;
  color: #868686;

}

.no-chats h3 {
  font-size: var(--size-m);
  font-weight: 500;
  margin: 0;
}

.no-chats p {
  text-align: center;
  font-size: var(--size-xs) !important;

}




/* ----- TAGS SLIDER ----- */
.used-tags-container {
  width: 100%;
  overflow-x: scroll;
  position: relative;
  scroll-behavior: smooth;
  margin: 1rem 0;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}



.used-tags-main {
  position: relative;
  padding: 0;
  margin: 0 .5rem;
  /* margin-right: 2rem;
  margin-left: 1.5rem; */
  /* padding-right: 1rem;
  padding-left: 1rem; */
  margin: 0 var(--size-xl);
}

.used-tags-main::after {
  position: absolute;
  content: '';
  width: 2rem;
  height: 100%;
  right: -2px;
  top: 0;
  background-image: linear-gradient(-90deg, var(--color-primary), transparent);
}

.used-tags-main::before {
  position: absolute;
  content: '';
  width: 2rem;
  height: 100%;
  left: -2px;
  top: 0;
  z-index: 1;
  background-image: linear-gradient(90deg, var(--color-primary), transparent);
}

.used-tags-main.scroll-start-reached::before {
  z-index: 0;
}

.used-tags-main.scroll-end-reached::after {
  z-index: 0;
  background-image: none;
}

.used-tags-main .nav-container {
  width: 100%;
  height: 100%;
  position: absolute;
}

.used-tags-main .nav-container .icon-container {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 3px;
  z-index: 2;
}


.used-tags-main .nav-container .icon-container svg {
  font-size: var(--size-xl);
  font-weight: 200;
  cursor: pointer;
  transition: all 100ms linear;
}

.used-tags-main .nav-container svg:hover {
  background-color: #2222221f;
  border-radius: 100%;
}


.nav-container .icon-container-left {
  left: -2rem;
}

.nav-container .icon-container-right {
  right: -2rem;
}

.used-tags {
  display: flex;
  align-items: center;
  width: max-content;
  position: relative;
  user-select: none;
}

.used-tags-container::-webkit-scrollbar {
  width: 0;
  height: 0;
  /* background-color: transparent; */
}


.used-tag {
  padding: .5rem 1.5rem;
  margin: 5px 10px;
  background-color: transparent;
  /* border: 1px solid gray; */
  border-radius: 20px;
  cursor: pointer;
  transition: all 100ms linear;
  background-color: var(--color-lighter);
  color: var(--color-light);
  font-size: var(--size-xs);
}


.used-tags-container .used-tag:hover {
  background-color: var(--color-lighter-lightened);
  color: var(--color-light-lightened);
}

.used-tag.active {
  background-color: var(--color-secondary);
  color: var(--color-dark);
}

.used-tag.used-tag.active:hover {
  background-color: var(--color-secondary-lightened);
}


.chat-search-container {
  display: flex;
}

.chat-search-container .input-with-icon {
  width: 75%;
}

.chat-search-container .input-with-icon input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 1px solid var(--color-lighter);
  border-right: transparent;
}

.chat-search-container .form-select {
  width: 25% !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border: 1px solid var(--color-lighter) !important;
  position: relative !important;
  font-size: var(--size-xs);
}

.chat-search-container .form-select:focus {
  box-shadow: none !important;
}

/* hover effect - chat-search */
.chat-search-container .input-with-icon input:hover {
  border: 1px solid var(--color-lighter-lightened);
  border-right: transparent;
}

.chat-search-container:hover>input,
.chat-search-container:hover>.form-select {
  border: 1px solid var(--color-lighter-lightened) !important;
  border-right: transparent;
}

.chat-search-container .form-select:hover {
  border: 1px solid var(--color-lighter-lightened) !important;
}


.owner-list-item {
  cursor: pointer;
  transition: background-color 100ms linear;
  border-radius: 10px;
  color: var(--color-dark);
}

.owner-list-item:hover {
  color: var(--color-dark-lightened);
  background-color: var(--color-lighter);
}

.owner-list-item.disabled {
  opacity: 0.7;
}

.owner-list-item.loading {
  background-color: var(--color-lighter);
  overflow-x: hidden;
  position: relative;
}

.owner-list-item.loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 80%;
  width: 10rem;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.267);
  transform: skew(30deg);
  animation: loading-animation 1s infinite;
}

@keyframes loading-animation {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: 80%;
    opacity: 0;
  }

  100% {
    left: 0%;
    opacity: 0;
  }
}

.selected-user {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  border: 1px solid var(--color-dark);
  gap: .2rem;
  font-size: var(--size-xs);
  padding: .1rem .3rem .1rem 1rem;
  transition: background-color 100ms linear;
}

.selected-user svg {
  font-size: 1.2rem;
}

.selected-user:hover {
  background-color: #ff000027;
  border-color: rgba(255, 0, 0, 0.329);
}

.dropdown-link.sort-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-link.sort-dropdown button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-link.sort-dropdown button svg {
  font-size: var(--size-l) !important;
}

.dropdown-link.sort-dropdown button::after {
  /* display: none; */
}

@media screen and (max-width: 786px) {
  .prompt-list-container {
    gap: 2rem;
    min-height: 50dvh;
    align-items: flex-start;
  }


  .prompt-list-container.organization-chats .prompt,
  .prompt-list-container.shared-chats .prompt {
    min-height: 10rem;
  }

  .prompt-list-container .prompt-main {
    width: 100%;
    height: auto;
  }

  .selected-user {
    padding: 0;
    padding-left: 0.8rem;
  }

  .prompt-list-container .prompt {
    min-height: 10rem;
    margin-bottom: 1rem;
    padding: 1.7rem 2rem 1rem 2rem;
  }

  .my-dropdown .dropdown-menu.show {
    right: 1rem;
  }

  .used-tag {
    /* font-size: 0.8rem; */
    padding: 8px 12px !important;
    margin: 0 5px !important;
  }

  .no-chats svg {
    width: 2rem;
  }

  .no-chats h3 {
    font-size: var(--size-s);
  }

  .no-chats p {
    font-size: var(--size-xxs);
  }
}