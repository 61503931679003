@import url("./base.css");

.subscription-container-mobile {
  display: none;
}

.current-subscription {
  display: flex;
  justify-self: center;
  align-self: center;
  padding: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}


.current-subscription .subscription {
  position: relative;
  height: auto;
}

.current-subscription .subscription::before {
  content: 'current plan';
  position: absolute;
  width: fit-content;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 2rem;
  top: 0;
  right: 0;
  background-color: var(--color-secondary);
  border-top-right-radius: 20px;
}


.subscription-upgrade-options {
  display: flex;
  gap: 2rem;
  padding: 3rem;
  border: 1px dotted var(--color-secondary);
  border-radius: 20px;
  flex-wrap: wrap;
}

.arrow .arrow-down {
  display: none;
}

.no-upgrades-message {
  margin: 0;
  color: rgb(223, 0, 0);
  margin: 4rem 0;
}


.tooltip-item-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.tooltip-item-container h6 {
  color: var(--color-secondary);
  text-align: left;
  margin-bottom: 1rem;
}

.tooltip-item-left {
  width: 60%;
  text-align: left;
}

.tooltip-item-container .seperator {
  width: 5%;
}

.tooltip-item-right {
  width: 35%;
}

.price .sub-text{
  display: inline;
}

@media screen and (max-width: 786px) {
  .subscription-container-lg {
    display: none;
  }

  .subscription-container {
    border: none;
    margin-top: 2rem;
  }

  .subscription-container-mobile {
    display: block;
  }

  .subscription-container-mobile .price .sub-text {
    position: unset;
    background-color: transparent;
  }


  .current-subscription {
    flex-direction: column;
    width: 100%;
    padding: 0rem;
    margin: 0 1rem;
  }

  .subscription-upgrade-options {
    margin: 0 1rem;
    padding: 0;
  }

  .arrow .arrow-right {
    display: none;
  }

  .arrow .arrow-down {
    display: block;
  }
}