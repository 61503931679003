@import url("./styles/base.css");
@import url("./styles/utility.css");
@import url("./styles/navbar.css");
@import url("./styles/forms.css");
@import url("./styles/adminDashboard.css");
@import url("./styles/userDashboard.css");
@import url("./styles/profile.css");
@import url("./styles/tag.css");
@import url("./styles/footer.css");
@import url("./styles/myChats.css");
@import url("./styles/subscriptions.css");
@import url("./styles/upgradeSubscriptions.css");
@import url("./styles/tokens.css");
@import url("./styles/toast.css");
@import url("./styles/manageUsers.css");
@import url("./styles/table.css");
@import url("./styles/share.css");
@import url("./styles/template.css");
@import url("./styles/apiKeys.css");
@import url("./styles/enterprisePayment.css");
@import url("./styles/savedPrompts.css");
@import url("./styles/myDropdown.css");
@import url("./styles/chat.css");
@import url("./styles/loadingSkeleton.css");
@import url("./styles/tabs.css");
@import url("./styles/templateMarketplace.css");
@import url("./styles/newChat.css");
@import url("./styles/adModal.css");
@import url("./styles/ui.css");


:root {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: .6rem;
    border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: .5rem;
    transition: all 100ms linear;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #b1b1b1;

}

body {
    /* font-family: 'Maven Pro', sans-serif !important; */
    font-family: 'Poppins', sans-serif !important;
    /* font-family: 'Playfair Display', serif !important; */
    /* border: solid; */
    background-color: var(--color-primary) !important;
    overflow-x: hidden;
    /* padding-top: calc(var(--nav-height) + 5rem); */
    padding-top: 10dvh;
    /* padding-bottom: 10dvh; */
    padding-bottom: var(--footer-height);
    min-height: 100vh !important;
    position: relative;
}

@media screen and (max-width: 786px) {
    body {
        padding-top: 15dvh;
        /* padding-bottom: 15dvh; */
    }
}
