@import url("./base.css");


.template-tabs {
  display: flex;
  justify-content: space-between;
  height: 2.5rem;
}

.template-tab {
  width: 100%;
  text-align: center;
  cursor: pointer;
  position: relative;
  transition: all 200ms linear;
}

.template-tab::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 50%;
  height: 3px;
  width: 40px;
  background-color: var(--color-secondary);
  border-radius: 5px;
  z-index: 1;
  transition: all 200ms linear;
  transform-origin: center;
  transform: translateX(-50%) scaleX(0);
}

.template-tab.active::after {
  transform: translateX(-50%) scaleX(1);
}

.template-tab.active {
  color: var(--color-secondary);
}

.template-tabs-container {
  position: relative;
}

.template-tabs-container span {
  display: block;
  width: 100vw;
  height: 1px;
  background-color: var(--color-lighter);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.template-grp {
  position: relative;
}

.list-item:hover .shared-tag {
  color: #00cf00;
  border: 1px solid #00cf00;
}

.shared-tag {
  font-size: var(--size-us);
  border: 1px solid green;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  width: auto;
  padding: 14px 1rem;
  color: green;
  height: 1.6rem;
  transition: all 100ms linear;
}

.template-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.template-header .d-flex {
  width: 50%;
}

.template-header .btn-container {
  width: 50%;
  margin: 0;
  display: flex;
  align-items: flex-end;
}

.template-header .btn-container .button {
  min-width: 12rem;
}

.filter-select-container {
  position: relative;
}

.filter-select {
  cursor: pointer !important;
  font-size: var(--size-xxs);
  border: none;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  padding-right: calc(1.3rem + var(--size-xs)) !important;
  position: relative;
  background-color: transparent;
  border: 1px dotted gray;
}

.filter-select:focus {
  outline: none !important;
}

.filter-select-container svg {
  position: absolute;
  top: 50%;
  right: calc((1rem + var(--size-xs)) / 2);
  transform: translateY(-50%);
  z-index: 1;
  font-size: var(--size-xxs);
  pointer-events: none;
}



#template-builder-modal .modal-body {
  padding: 0;
}

#template-builder {
  height: 70dvh;
  display: flex;
}

#template-builder .left-panel {
  width: 40%;
  height: 100%;
  overflow-y: scroll;
  background-color: var(--color-lightest);
  padding: 3vw;
  position: relative;
}

#template-builder .left-panel h5 {
  font-size: var(--size-s);
}

#template-builder .right-panel h5 {
  font-size: var(--size-s);
}


#template-builder .right-panel {
  width: 60%;
  height: 100%;
}

#template-builder .right-panel .droptarget {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.template-items-category {
  margin-top: 3rem;
}

.template-items-category .template-item-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.template-items-category .template-item-header p {
  color: var(--color-light);
  margin: 0;
}

.template-items-category .template-item-header span {
  height: 1px;
  width: 100%;
  background-color: #a7a7a7;
}

.template-items {
  display: flex;
  gap: var(--size-xl);
  flex-wrap: wrap;
}

.drag-item-container {
  display: flex;
  align-items: center;
  position: relative;
}

.prem-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: var(--size-xs);
  height: var(--size-xs);
  fill: var(--color-golden)
}

.drag-item-container button {
  border-radius: 0 20px 20px 0;
  width: 2rem;
}

.drag-item-container button svg {
  pointer-events: none;
}

.template-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-lighter);
  width: 6rem;
  height: 6rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 150ms linear;
  padding: 0 10px;
  text-align: center;
}

.template-item svg {
  font-size: var(--size-l);
  color: var(--color-dark);
  transition: all 150ms linear;
}

.template-item p {
  margin: 0;
  font-size: var(--size-xxs) !important;
  transition: all 150ms linear;
}

.template-item:hover {
  background-color: var(--color-dark);
}

.template-item:hover svg,
.template-item:hover p {
  color: var(--color-primary);
}

.template-elements-container {
  padding: 3vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 1.5rem;
  /* gap: var(--size-s); */
}

.template-elements-container .item-drop-indicator {
  width: 80%;
  height: 25px;
  border-radius: 6px;
  background-color: #d1d1d1;
  margin: auto;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  opacity: 50%;
  border: 1px dotted #7a7a7a;
  gap: 10px;
  display: flex !important;
  opacity: 0%;
}

.template-elements-container .element-dropping .item-drop-indicator {
  opacity: 50%;
}

#template-builder .right-panel .template-elements-container .droptarget {
  width: auto;
  height: auto;
}

.template-elements-container .item-drop-indicator p {
  font-size: var(--size-xxs) !important;
  margin: 0 !important;
  text-align: center;
}

.template-elements-container .template-element {
  padding: 3vw;
  background-color: var(--color-lightest);
  width: 100%;
  border-radius: 10px;
  transition: all 150ms linear;
  margin-bottom: 10px;
  position: relative;
}

.template-elements-container .element-dropping .template-element {
  pointer-events: none;
}

.template-elements-container .template-element.dragged {
  cursor: move;
}

.template-elements-container .template-element .element-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.template-elements-container .template-element .element-header .left {
  display: flex;
  align-items: center;
  gap: 5px;
}

.template-elements-container .template-element .element-header .left svg {
  cursor: grab;
}

.template-elements-container .template-element .element-header .left p {
  margin: 0;
  font-size: var(--size-xs) !important;
}

.template-elements-container .template-element .options {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-xs);
  align-items: center;
  margin: 5px 0 8px 0;
}

.left-panel .form-container {
  padding: 3vw;
  margin: 0;
}





.file-upload-container:hover {
  border-color: var(--color-lighter-lightened);
  transition: all 150ms linear;
}

.file-upload-container:hover>.file-upload-label {
  transition: all 150ms linear;
  opacity: 80%;
}


.template-builder-file-selector {
  cursor: pointer;
  opacity: 0;
  position: relative;
  z-index: 1;
}

.file-upload-label {
  position: absolute;
  z-index: 0;
  left: var(--size-s);
  font-size: var(--size-xxs) !important;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-light);
}

.file-upload-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--color-lighter);
  border-radius: 4px;
  width: 100%;
  position: relative;
  min-height: 3.2rem;
}

.selected-file-details {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: var(--size-s);
  padding-right: var(--size-us);
}

.file-upload-container span {
  font-size: var(--size-xxs);
  overflow-wrap: anywhere;
}

.file-upload-container .close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
}




.sticky-footer {
  position: sticky;
  left: 0;
  padding: 0 3vw;
  /* transition: all 150ms linear; */
  bottom: -2rem;
  background-color: var(--color-primary);
  width: calc(100%);
  padding: 1rem 3vw 3rem 3vw;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.sticky-footer.is-pinned {
  padding-bottom: 3rem;
  border-top: 1px solid var(--color-lightest);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}


@media screen and (max-width: 786px) {
  .template-header {
    flex-direction: column;
    gap: .3rem;
  }

  .template-header .d-flex {
    width: 100%;
  }

  .template-header .btn-container {
    width: 100%;
  }

  .template-header .btn-container .button {
    min-width: 10rem !important;
  }

  #template-builder .left-panel h5 {
    font-size: var(--size-xs);
    margin-top: 1rem;
  }

  #template-builder .right-panel h5 {
    font-size: var(--size-xs);
    margin-top: 1rem;
  }

  .template-items-category {
    margin-top: 1.5rem;
  }

  .template-item-header p {
    font-size: var(--size-xxs) !important;
  }

  .template-item {
    width: 4.5rem;
    height: 4.5rem;
  }

  .template-item svg {
    font-size: var(--size-s) !important;
  }

  .template-item p {
    font-size: var(--size-us) !important;
  }

  .no-elements-message {
    font-size: var(--size-xxs) !important;
  }

  .template-elements-container .template-element .element-header .left p {
    font-size: var(--size-xxs) !important;
  }

  .template-element .label {
    font-size: var(--size-us) !important;
  }

  .template-element form div div label {
    font-size: var(--size-us) !important;
  }

  #template-builder .chat-info {
    max-width: 70%;
  } 
}