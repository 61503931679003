.new-chat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 2dvh;
    min-height: calc(90dvh - var(--nav-height));
}

.chat-assistance-main {
    width: 100%;
    overflow-x: scroll;
    display: flex;
}

.chat-assistance-container {
    display: flex;
    gap: var(--size-s);
    margin: 5dvh 0;
}

.chat-assistance {
    border: 1px solid var(--color-secondary);
    border-radius: 20px;
    padding: var(--size-m);
    padding-bottom: 2rem;
    width: 25rem;
    max-width: 70vw;
    position: relative;
}

.chat-assistance button {
    position: absolute;
    right: var(--size-m);
    bottom: 0.5rem;
}

.chat-assistance button svg {
    transition: transform 500ms;
    fill: var(--color-light);
}


.chat-assistance-main::-webkit-scrollbar {
    width: 0;
    height: 0;
    /* background-color: transparent; */
}

.chat-assistance h4 {
    font-size: var(--size-s);
    background: linear-gradient(360deg, var(--color-secondary), var(--color-secondary-lightened));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

}

.assistant-items {
    margin: 2dvh 0;
}

.assistant-item {
    background-color: var(--color-lightest);
    display: flex;
    margin: var(--size-xxs) 0;
    padding: 10px 20px;
    border-radius: 40px;
    width: fit-content;
    max-width: 100%;
    cursor: pointer;
    transition: all 200ms linear;
}

.assistant-item:hover {
    background-color: var(--color-lighter);
}

.chat-select {
    width: fit-content;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    padding: 2px;
    /* border: 1px solid var(--color-lighter); */
    border-radius: 10px;
    margin-bottom: 10px;
}

.chat-select-item {
    padding: 10px 15px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 200ms linear;
}

.chat-select-item:hover {
    background-color: var(--color-lighter-lightened);
}

.chat-select-item.selected-provider {
    background-color: #00bfa660;
}

.chat-select-item.selected-provider:hover {
    background-color: #00bfa644;
}

.selected-template {
    margin-top: 5px;
    border-radius: 8px;
    border: 1px solid var(--color-lighter-lightened);
    background-color: var(--color-lightest);
    padding: var(--size-us) var(--size-s);
}

.selected-template .remove-icon {
    font-size: var(--size-l);
    padding: calc(var(--size-xs) / 2);
    cursor: pointer;
}

.selected-template .remove-icon:hover {
    border-radius: 100%;
    background-color: #22222227;
}