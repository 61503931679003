@import url("./base.css");


.dropdown-menu {
  position: relative;
  z-index: 0;
}

.my-dropdown {
  position: relative;
}

.my-dropdown button:disabled {
  pointer-events: unset;
}

.my-dropdown .dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  width: 100% !important;
}

.dropdown-menu.show {
  display: block;
  position: absolute;
  z-index: 1 !important;
}

.dropdown-menu.show.margin-top-2-5 {
  /* margin-top: 2.5em; */
}

/* .dropdown-menu-overlay.show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: -1;
} */

.dropdown-menu .dropdown-title {
  display: none;
}

@media screen and (max-width: 786px) {
  .my-dropdown.show::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 6 !important;
  }

  .my-dropdown .dropdown-menu {
    display: block;
    position: fixed;
    width: 100%;
    z-index: 7 !important;
    border: 1px solid white;
    border-radius: 10px 10px 0 0;
    padding-top: 1.5rem;
    top: unset;
    right: 0 !important;
    bottom: 0;
    transform: translateY(100%);
    transition: all 200ms linear;
  }

  .my-dropdown .dropdown-menu::before {
    content: "";
    display: flex;
    width: 2.5rem;
    height: 5px;
    background-color: var(--color-lighter);
    border-radius: 10px;
    margin: auto;
    margin-bottom: 2rem;
  }

  .my-dropdown .dropdown-menu.show {
    transition: all 200ms linear;
    transform: translateY(0%);
  }

  .dropdown-menu-content {
    max-height: 50dvh;
    overflow: auto;
    padding-right: 10px;
  }

  .dropdown-menu .dropdown-title {
    display: block;
    font-weight: 600;
    font-size: var(--size-s) !important;
  }

  .my-dropdown .dropdown-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid var(--color-lightest);
    border-radius: 5px;
  }

  .my-dropdown .dropdown-item:first-child {
    border-top: none;
  }
}