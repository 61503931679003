@import url("./base.css");

.form {
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    /* background-color: white; */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;  */
    /* border: solid; */
}

.form.md {
    width: 24rem;
    justify-content: center;
}

.form.lg {
    width: 50rem;
}

.form.xl {
    width: 80%;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 1rem 0;
    /* border: solid; */
    width: 100%;
    /* max-width: 60rem !important; */
    /* border: solid; */
}

.form-grp {
    display: flex;
    gap: 4%;
}

.form-grp .input-grp {
    width: 48%;
}

.input-grp {
    display: flex;
    flex-direction: column;
    /* border: solid; */
    width: 100%;
}

.input-grp label {
    font-size: var(--size-xxs);
}

input[type="checkbox"] {
    border-color: var(--color-light);
    width: var(--size-s);
    height: var(--size-s);
}

input:not([type="checkbox"]),
textarea {
    border: none;
    /* background-color: rgba(241, 241, 241, 0.418); */
    width: 100%;
    border-radius: 5px;
    transition: all 100ms linear;
    padding: 0 .7rem;
    border: 1px solid var(--color-lighter);
    font-size: var(--size-xs) !important;
}


input {
    height: 3.2rem;
}

select.form-select {
    height: 3.2rem;
    font-size: var(--size-xs) !important;
}

input:not([type="checkbox"]):hover,
textarea:hover {
    outline: none;
    border: none;
    border: 1px solid var(--color-secondary);
}

input:not([type="checkbox"]):focus,
textarea:focus {
    outline: none;
    border-bottom: 1px solid var(--color-secondary);
}

input:not([type="checkbox"]):focus:hover,
textarea:focus:hover {
    border: 1px solid var(--color-lighter-lightened);
    border-bottom: 1px solid var(--color-secondary);
}

textarea {
    /* height: 5rem; */
    padding-top: 1rem;
    padding-bottom: 1rem;
    max-height: 10rem;
    resize: none !important;
}

.password-options {
    display: flex;
    justify-content: space-between;
}

.show-password {
    margin-top: .5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .5rem;
    font-size: 0.8rem;
}

.show-password input[type="checkbox"] {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
}

.show-password label {
    cursor: pointer;
}

.reg-step-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.reg-step-container .step {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 225, 255);
}

.reg-step-container .connector {
    width: 4rem;
    height: 2px;
    background-color: rgb(0, 225, 255);
}

.reg-step-container .step2,
.reg-step-container .connector2 {
    transition: all 150ms linear;
    background-color: rgb(189, 189, 189);
}

.reg-step-container .step2.active,
.reg-step-container .connector2.active {
    background-color: rgb(0, 225, 255);
}


.radio-container {
    display: flex;
    gap: 2rem;
}

.radio-grp {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.radio-grp input,
.radio-grp label {
    cursor: pointer;
}

.input-grp.file-upload {
    position: relative;
}

.input-grp.file-upload svg {
    position: absolute;
    top: 50%;
    right: 1rem;
    font-size: var(--size-xl);
    color: rgb(97, 97, 97);
    z-index: -1;
}

.input-grp.file-upload input[type=file]::file-selector-button {
    display: none;
}

.input-grp.file-upload input[type=file] {
    padding-top: 0.8rem;
    padding-right: 4rem;
    cursor: pointer;
}

.pending-icon {
    font-size: 3rem;
    color: rgb(54, 54, 54);
}

.declined-icon {
    font-size: 3rem;
    color: rgb(255, 0, 0);
}

.input-with-icon {
    position: relative;
}

.input-with-icon input {
    padding-right: 3.5rem;
}

.input-with-icon .button-icon {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
}

.forgot-password-link {
    text-align: right;
    padding-top: .5rem;
    color: var(--text-sec);
    text-decoration: none;
    transition: color 100ms linear;
}

.forgot-password-link:hover {
    color: #535353;
}

.free-plan-features {
    padding: 1.5rem;
    border: 1px solid rgb(231, 231, 231);
    border-radius: 10px;
}

.free-plan-feature-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.free-plan-feature {
    margin: 1rem;
    padding: 1rem;
    margin-left: 0;
    border: 1px solid transparent;
    border-radius: 10px;
    width: 11rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: #f0f0f0be;
    transition: all 150ms linear;
    cursor: pointer;
    font-size: var(--size-xxs);
}

.free-plan-feature:hover {
    border: 1px solid #00BFA5;
}

.free-plan-feature svg {
    font-size: 2rem;
    color: #353535;
}

.free-plan-feature svg.f-plus {
    font-size: 3rem;
}

.my-checkbox {
    width: 1rem !important;
    height: 1.4rem !important;
}

.my-checkbox:hover {
    border: 1px solid rgb(148, 148, 148);
}

.my-checkbox:focus {
    border: 1px solid rgb(148, 148, 148);
}

.my-checkbox:focus:hover {
    border: 1px solid rgb(148, 148, 148);
}


.enterprise-invite-form {
    width: 40rem;
}

.enterprise-invite-form .btn-container {
    align-items: flex-start;
}

.no-hover-input {}

.counter-input {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.counter-input input {
    width: 5rem;
    -moz-appearance: textfield;
}

.counter-input input:hover {
    border: 1px solid rgba(128, 128, 128, 0.342);
}

.counter-input input:focus {
    border: 1px solid rgba(128, 128, 128, 0.342);
}

.counter-input input::-webkit-outer-spin-button,
.counter-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.conditions a {
    color: #222;
}

.conditions a:hover {
    color: #383838;
}

.custom-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
}

.custom-divider span {
    height: 1px;
    width: 100%;
    background-color: var(--color-light);
    opacity: 30%;
}

.custom-divider p {
    margin: 0;
    color: var(--color-light);
    font-size: var(--size-xxs) !important;
}

/* Three Dot Loading Animation  */
.dot-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dot {
    width: 7px;
    height: 7px;
    margin: 5px;
    /* Google blue */
    border-radius: 50%;
    transition: all 100ms linear;
    animation: dotAnimation 1.4s infinite ease-in-out both;
}

.dot1 {
    animation-delay: 0;
    background-color: #ea4335;
}

.dot2 {
    animation-delay: 0.16s;
    background-color: #fbbc05;
}

.dot3 {
    animation-delay: 0.32s;
    background-color: #34a853;
}

.dot4 {
    animation-delay: 1s;
    background-color: #4285f4;
}


@keyframes dotAnimation {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(4px);
    }

    100% {
        transform: translateY(0);
    }
}


@media screen and (max-width: 500px) {

    .form-grp {
        flex-direction: column;
        gap: 2rem;
    }

    .form-grp .input-grp {
        width: 100%;
    }

    .free-plan-feature {
        width: 100%;
        justify-content: flex-start;
    }

    .free-plan-feature svg {
        font-size: 1.3rem;
    }

    .free-plan-feature svg.f-plus {
        font-size: 1.4rem;
    }

    .enterprise-invite-form {
        width: 100%;
    }

    div.form.lg {
        padding-top: 0;
    }

    div.form.md {
        padding-top: 0;
    }

    .w-mobile-only-full{
        width: 100%;
    }

    /* input:not(checkbox),
    select {
        height: 2.5rem !important;
    } */
}