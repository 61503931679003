.chat-main-container {
    padding-top: 48px;
}

.shared-chat-band {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    position: fixed;
    top: calc(var(--nav-height));
    left: 0;
    background-color: var(--color-secondary);
    width: 100%;
    border-radius: 0 0 5px 5px;
    z-index: 2;
}

.shared-chat-band p {
    margin: 0;
    font-size: var(--size-xxs) !important;
    color: var(--color-dark-lightened);
}

.chat-header {
    margin-bottom: 2rem;
}

.chat-name {
    display: flex;
    justify-content: space-between;
}

.chat-name span {
    font-size: var(--size-m);
}

.chat-info {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-light);
    opacity: 70%;
    width: 100%;
    height: 1px;
}

.chat-profile-pic {
    width: calc(var(--size-ul));
    height: calc(var(--size-ul));
    margin-top: 1rem;
}

.chat-info span {
    margin: 0;
    min-width: 10vw;
    width: auto;
    text-align: center;
    padding: 0 1.2rem;
    color: var(--color-dark) !important;
    font-size: var(--size-xxs);
    font-weight: 500;
    background-color: var(--color-primary);
}

.chat-header-fixed {
    position: fixed;
    top: calc(var(--nav-height));
    left: 0;
    background-color: var(--color-primary);
    padding: 1rem 0;
    min-height: 4rem;
    width: 100%;
    z-index: 5 !important;
    box-shadow: rgb(100 100 111 / 6%) -1px 20px 29px 0px;
    border-top: 1px solid var(--color-lightest);
}

.chat-header-fixed .question {
    font-size: 1.1rem !important;
    display: flex;
    justify-content: space-between;
    white-space: pre-line;
}

.chat-header-fixed .question span {
    font-size: var(--size-s);
}

.copy-btn {
    position: absolute;
    top: 1rem;
    right: 1.2rem;
}

.copy-icon {
    font-size: var(--size-s);
    fill: rgb(68, 68, 68);
}

.back-icon {
    font-size: var(--size-xxl);
    margin-right: 10px;
}


.textarea-container {
    position: relative !important;
}

.textarea-container textarea {
    padding-right: 4rem;
}

.textarea-container button {
    position: absolute !important;
    right: 1.3rem;
    top: unset;
    bottom: 15px;
    background-color: var(--color-secondary);
    border-radius: 10px
}

.textarea-container button:hover {
    background-color: var(--color-secondary-lightened);
}

.textarea-container button:disabled {
    background-color: transparent;
}

.textarea-container button svg {
    fill: var(----color-dark);
}

.textarea-container button:disabled svg {
    opacity: 0.6;
}

.chat-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: calc(var(--size-s));
    padding: 5px 15px;
    border-radius: 10px 10px;
    width: fit-content;
    margin-bottom: calc(var(--size-s) * -1.6);
    margin-left: auto;
    margin-right: calc(var(--size-xxl) * -1.3);

    box-shadow: 0 4px 30px var(--color-glass-lightened);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
}
.chat-actions button:hover {
    opacity: 0.8;
    transition: all 150ms linear;
}

/*  ===================  MESSAGES  ================ */
.message-container {
    min-height: 40vh;
}

.messages {
    margin-top: 3rem;
    margin-bottom: 10rem;
}

.message {
    /* margin-top: 1.5rem; 4*/
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.message-header .chat-profile-pic {
    margin-top: calc(var(--size-xxl) + var(--size-uus) - 10px);
}

.message.self-message {
    align-items: flex-end;
}

.message-content {
    font-size: var(--size-xs) !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    white-space: pre-line;
    margin-bottom: 8px;
    overflow-wrap: anywhere;
    overflow: visible;
    position: relative;
}

.message-header {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    gap: 1rem;
    max-width: 90%;
}

.message.self-message .message-header {
    flex-direction: row-reverse;
}

.message-body {
    display: flex;
    flex-direction: column;
}


.message.self-message .message-body {
    align-items: flex-end;
}

.message-content {
    padding: var(--size-s) var(--size-xxl);
    border-radius: 10px;
    background-color: var(--color-message-bg);
    margin: 0;
}

.message.self-message .message-content {
    /* padding-top: 1.5rem; */

}

.message-by {
    font-size: var(--size-us);
    color: var(--color-light);
    margin-bottom: 0.5rem;
    color: #2d7c02;
    margin: calc(var(--size-uus) - 5px) 0 var(--size-uus) 0;
}

.message-at {
    font-size: var(--size-us) !important;
    color: var(--color-light);
    text-align: left;
    margin-bottom: 0.3rem;
    opacity: 0;
    transition: all 150ms ease-in-out;
    padding: 0 var(--size-us);
}
.message:hover .message-at {
    opacity: 1;
}

.message.self-message .message-content {
    text-align: left;
}

.message-details {
    font-size: var(--size-us) !important;
    color: var(--color-light);
}

.message-actions {
    background-color: var(--color-message-actions-bg);
    border: 1px solid var(--color-lightest);
}

.chat-template-detail {
    width: 24rem;
    max-width: 60vw;
}

.template-content-container {
    overflow: hidden;
    transition: height 0.3s ease-out;
    height: 0;
    overflow-y: auto;
}

.template-content-container::-webkit-scrollbar-thumb {
    background-color: var(--color-glass);
}

.template-content-container::-webkit-scrollbar {
    width: 7px;
}

.template-content-container.open {
    height: auto;
    /* Allows content to expand fully */
}

/* Optional: For a more precise effect with fixed max height */
.template-content-container.open {
    height: calc(100px + 2rem);
    /* Adjust based on content size */
}

.template-content-container.closed {
    height: 0;
}

.continue-chat-container {
    position: sticky;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0 1.5rem 0;
    background-image: linear-gradient(transparent 10%, var(--color-primary));
}

.send-message-button {
    border: none;
    width: calc(var(--size-s) * 2);
    height: calc(var(--size-s) * 2);
}


/*  ===================  RESPONSES  ================ */
.responses {
    width: 100%;
}

.response {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    gap: 1rem;
    max-width: 90%;
}

.response-body {
    font-family: 'Poppins', sans-serif !important;
    background-color: var(--color-response-bg);
    padding: var(--size-s) var(--size-xxl);
    /* padding-bottom: 2rem; */
    margin: 0 0;
    border-radius: 10px;
    white-space: pre-line;
    overflow: visible;
    position: relative;
    z-index: 0;
    width: 100%;
}

.absolute-provider-logo {
    width: var(--size-m) !important;
    height: var(--size-m) !important;
    position: absolute;
    top: var(--size-us);
    left: calc(var(--size-s));
    padding: 2px;
    border-radius: 100%;
    background-color: var(--color-primary);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    opacity: 90%;
}

.response-by {
    font-size: var(--size-us) !important;
    color: var(--color-response-highlight);
}

.response-body .response-body-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: calc(var(--size-uus) - 5px) 0 var(--size-uus) 0;
    gap: var(--size-ul);
}

.response-body pre {
    font-family: 'Poppins', sans-serif !important;
    /* white-space: pre-wrap; */
    word-wrap: break-word;
    font-size: var(--size-xs);
}

.response-actions {
    background-color: var(--color-response-actions-bg);
    border: 1px solid var(--color-lightest);
}

/* Markdown */
.response-body p {
    white-space: pre-wrap;
    margin: 0;
}

.response-body ul li {
    white-space: normal;
}

.response-body span i {
    display: block;
    margin-top: 1rem;
}

.markdown ol,
.markdown ul {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
}

.response-body h1 {
    font-size: calc(var(--size-m) + 3px);
}

.response-body h2 {
    font-size: calc(var(--size-m) - 1px);
}

.response-body h3 {
    font-size: calc(var(--size-m) - 4px);
}

.response-body h4 {
    font-size: var(--size-s);
}

.response-body h5 {
    font-size: var(--size-xs);
}

.response-body h6 {
    font-size: var(--size-xxs);
}

/* Markdown table */
.response-body .markdown-table-container {
    overflow: auto;
}

.response-body .markdown-table-container::-webkit-scrollbar {
    height: 8px;
}

.response-body table {
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: .25rem;
    margin-top: .25rem;
    width: 100%
}

.response-body th {
    background-color: var(--color-lighter);
    padding: 15px 10px;
    border-bottom: 1px solid var(--color-lightest);
    font-weight: 600;
}

.response-body th:not(:last-of-type) {
    border-right: 1px solid var(--color-lightest);
}

.response-body th:first-of-type {
    border-top-left-radius: 10px;
}

.response-body th:last-of-type {
    border-top-right-radius: 10px;
}

.response-body tbody tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 10px;
}

.response-body tbody tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 10px;
}

.response-body tr td:last-of-type {
    border-right: 1px solid var(--color-lighter);
}

.response-body td {
    border-bottom: 1px solid var(--color-lighter);
    border-left: 1px solid var(--color-lighter);
    padding: 10px 10px;
}

/* Markdown inline code */
.inline-code {
    padding: 3px 7px;
    background: var(--color-glass-lightened);
    border-radius: 4px;
}

/* Markdown code block */
:not(pre)>code.hljs,
:not(pre)>code[class*='language-'] {
    border-radius: 0.3em;
}

.code-block {
    border-radius: 10px;
    background-color: var(--color-code-bg);
    overflow: auto;
    border: 1px solid var(--color-code-border);
}

.code-block::-webkit-scrollbar {
    height: 8px;
}

.code-content {
    padding: 1rem 1.5rem;
}

.code-block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background-color: var(--color-dark-lightened);
    position: sticky;
    left: 0;
}

.code-block-lang {
    color: var(--color-primary);
    font-size: var(--size-us) !important;
}

.button-copy-code {
    all: unset;
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: var(--size-us);
    color: var(--color-primary);
    position: sticky;
}

.hljs {
    color: var(--color-code-text-default);
    /* General text */
}

.hljs-comment {
    color: var(--color-code-comment);
}

.hljs-meta {
    color: var(--color-code-meta);
}

.hljs-built_in,
.hljs-class .hljs-title {
    color: var(--color-code-built-in);
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword,
.hljs-literal {
    color: var(--color-code-keyword);
}

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
    color: var(--color-code-string);
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
    color: var(--color-code-variable);
}

.hljs-bullet,
.hljs-link,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
    color: var(--color-code-bullet);
}

#response-actions button:hover {
    opacity: 0.8;
}

.regenerate-container {
    position: relative;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    width: fit-content;
}

.regenerate-options {
    position: absolute;
    top: 100%;
    left: 1rem;
    z-index: 3;
    display: flex;
    gap: 1rem;
    background-color: var(--color-primary);
    border-radius: 10px;
    box-shadow: rgba(3, 214, 196, 0.3) 0px 0px 0px 3px;
    padding: 1rem;
    width: 28rem;
    max-width: 100%;
    font-size: var(--size-xs);
}

.regenerate-options .link:first-child {
    border-right: 1px solid rgb(172, 172, 172);
    padding-right: 1rem;
}

.regenerate-container span.overlay {
    content: '';
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1;
}


/*  ===================  NEW CHAT  ================ */
.chat-sticky-footer {
    position: sticky;
    bottom: 0;
    left: 0;
    border-radius: 8px 8px 0 0;
    padding: var(--size-xs) 0 var(--size-l) 0;
    background: linear-gradient(to bottom, transparent, var(--color-primary) 40%);
}

.chat-sticky-footer.is-sticky {
    padding-top: calc(var(--size-ul) + var(--size-xxs));
}


/*  ===================  NOTES  ================ */
.notes {
    margin-top: 1rem;
    /* padding-top: 0.5rem; */
    border-top: 1px solid var(--color-lighter);
}

.notes.have-notes {
    margin-top: 1.5rem;
}
.notes.no-notes {
    /* margin-top: 1rem; */
    border: none;
}

.message-notes {
    border-color: var(--color-glass);
}

.message-notes.no-note {
    padding-top: 0;
    margin-top: 0;
    padding-left: 6rem;
    border: 0;
}

.profile-image {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    object-fit: cover;
}

.note-container {
    padding: 1rem 1.5rem;
    border-radius: 10px;
    background-color: var(--color-primary);
    margin: 1rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.message-notes .note-container {
    background-color: var(--color-glass-lightened);
}


.note-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    gap: 9px;
}


.note-header p {
    text-align: right !important;
    color: var(--text-main-hover);
    margin: 0;
}

.note-container h5 {
    margin: 0;
    font-size: var(--size-xxs);
    font-weight: 500;
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .5rem;
}

.note-container textarea {
    margin-bottom: 1.5rem;
    font-size: var(--size-xxs) !important;
}

.note-container .date {
    font-size: var(--size-us) !important;
}

.note-container p {
    text-align: left;
    width: 100%;
    font-size: var(--size-xxs) !important;
}

.note-content {
    padding-left: calc(var(--size-xxl) + 9px);
    margin-top: -5px;
    margin-bottom: 0.7rem;
}

.textarea-wrapper {
    position: relative;
    margin-bottom: 1.5rem;
    width: 100%;
    height: 6rem;
}

.textarea-formatted-text,
.textarea-field {
    width: 100%;
    height: 100%;
    font: inherit;
    font-size: var(--size-xxs);
    padding: 12px 8px;
    margin: 0;
    font-weight: 400;
    overflow-y: auto;
}

.textarea-formatted-text {
    background-color: var(--color-primary);
    color: var(--color-dark);
    position: absolute;
    top: 0;
    left: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    border: 1px solid;
    border-radius: 5px;
    pointer-events: none;
    scrollbar-width: none;
}

.textarea-formatted-text::-webkit-scrollbar {
    display: none;
}

.textarea-formatted-text .mention {
    font-size: var(--size-xxs) !important;
    font-weight: 400 !important;
}

.textarea-field {
    background: transparent;
    color: transparent;
    resize: none;
    position: relative;
    caret-color: var(--color-dark);
    overflow-y: auto
}

.textarea-field::placeholder {
    color: var(--color-light);
    opacity: 1;
}

.mention-dropdown-list-item:hover {
    transition: all 150ms ease-in-out;
    background-color: var(--color-lighter);
}

.mention {
    color: rgb(16, 91, 203);
    background-color: rgb(211, 224, 236);
    font-size: var(--size-xxs);
    font-weight: 500;
}

.message-notes .mention {
    background-color: #a9c49b;
}



@media screen and (max-width: 786px) {

    .note-container {
        padding: 0.2rem 0.7rem 1rem 0.7rem;
    }

    .chat-main-container {
        padding-top: 0;
    }

    .absolute-provider-logo {
        width: 17px;
    }

    .message-header,
    .response {
        max-width: 98%;
        gap: 0.5rem;
    }

    .message-header .chat-profile-pic {
        margin-top: calc(var(--size-xxl) + var(--size-uus) - 8px);
    }

    .response .chat-profile-pic {
        margin-top: 10px;
    }

    .message-content,
    .response-body {
        padding: var(--size-s) 20px;
    }

    .chat-actions {
        /* width: calc(100% + 20px + 20px); */
        margin-right: calc(20px * -1.3);
        /* margin-left: calc(20px * -1); */
    }
}