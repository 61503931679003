@import url("./base.css");

.main-nav {
  /* display: flex;
  flex-direction: column; */
  background-color: var(--color-primary);
  /* border: solid; */
  width: 100%;
  /* transition: all 150ms linear; */
  top: 0;
  left: 0;
  position: fixed;
  z-index: 6 !important;
  /* box-shadow: none; */
  border-bottom: 1px solid rgba(212, 212, 212, 0.589);
}

.main-nav.add-shadow {
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  border-bottom: 1px solid rgba(212, 212, 212, 0.589);

}

.main-nav .header {
  width: 100%;
  height: 3rem;
  background-color: var(--color-dark);
}

.main-nav .header .container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 0.4rem;
}


.main-nav .main {
  display: flex;
  justify-content: space-between;
  height: var(--nav-height);
  background-color: var(--color-primary);
}

.nav-left {
  /* border: solid; */
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav-right {
  /* border: solid; */
  width: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem;
}

.logo {
  text-decoration: none;
  font-size: var(--size-m);
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  color: var(--color-dark);
}

.home-branding {
  gap: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.home-logo {
  width: calc(var(--size-l) + var(--size-s));
  height: calc(var(--size-l) + var(--size-s));
  object-fit: cover;
  transition: 0.3s;
}

.home-logo:hover {
  transform: rotate(180deg);
}

.home-company {
  color: var(--color-dark);
  font-size: var(--size-m);
  font-style: normal;
  font-weight: 700;
}

.home-branding.responsive .home-logo {
  width: 32px;
  height: 32px;
}

.home-branding.responsive .home-company {
  font-size: 1.2rem;
}


.nav-right a,
.nav-menu a {
  text-decoration: none;
  color: var(--text-main);
}

.nav-right a:hover,
.nav-menu a:hover {
  color: var(--text-main-hover);
}

.nav-right a.active,
.nav-menu a.active {
  color: var(--color-secondary);
}

.nav-right a.active:hover,
.nav-menu a.active:hover {
  color: var(--color-secondary-lightened);
}

.dropdown-link button {
  background-color: transparent !important;
  color: var(--color-dark);
  border: none;
  width: fit-content;
  padding: 0;
  font-weight: normal !important;

}

.dropdown-link button:hover {
  color: var(--color-dark-lightened);
}

.dropdown-link button.show {
  color: var(--color-dark);
}

.dropdown-link.active button {
  color: var(--color-light);
  font-weight: 500;
}

.dropdown-link.active button:hover {
  color: var(--color-secondary-lightened);
}

.dropdown-item:focus {
  background-color: var(--color-dark) !important;
}

.dropdown-item.active {
  background-color: var(--color-dark) !important;
  color: var(--color-primary) !important;
}

.dropdown-link a:focus {}

.toggle-btn-container {
  display: none;
}

.nav-menu {
  display: none;
}

.profile-pic {
  width: 3rem;
  height: 3rem;
  background-size: cover;
  background-position: center;
  border-radius: 100%;
  display: inline-block;
}





/* ------ LANGUAGE -------- */

#google_translate_element select {
  background: var(--color-primary);
  color: var(--color-dark);
  border: none;
  border-radius: 3px;
  padding: 6px 8px;
}


#google_translate_element span {
  display: none !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  ;
}

.goog-te-banner-frame {
  display: none !important;
}

#goog-gt-tt,
.goog-te-balloon-frame {
  display: none !important;
}

.goog-text-highlight {
  background: none !important;
  box-shadow: none !important;
}


.nav-right-mobile {
  display: none;
  justify-content: center;
  align-items: center;
}

.nav-token-container {
  margin-right: 2.5rem;
}

.nav-token-container .token-container {
  gap: 1vw;
}

.nav-token-container .token-container svg {
  font-size: 1.4rem;
}

.nav-token-container .token-container p {
  font-size: 0.9rem;
}




@media screen and (max-width: 786px) {
  body {
    padding-top: 6rem;
  }

  .main-nav .container {
    padding: 0 5vw;
  }

  .nav-right {
    display: none;
  }

  .nav-right-mobile {
    display: flex;
    gap: 0;
  }

  .toggle-btn-container {
    display: flex;
    justify-content: flex-end !important;
    align-items: center;
    width: 70%;
    position: relative;
  }

  .toggle-btn-container input[type="checkbox"] {
    width: 1.7rem;
    height: 1.7rem;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 8;
  }

  .toggle-btn {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
    position: absolute;
    z-index: 7;
  }

  .toggle-btn span {
    width: 100%;
    height: 1.5px;
    background-color: var(--color-dark);
    transition: all 100ms linear;
  }

  .main-nav {
    background-color: var(--color-primary);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }

  .nav-menu {
    width: 100vw;
    height: 100dvh;
    position: fixed;
    bottom: 0;
    background-color: var(--color-primary);
    display: block;
    gap: 2rem;
    transition: all 200ms linear;
    z-index: 6 !important;
    padding-top: 0.4rem;
  }

  .nav-menu .nav-left {
    height: var(--nav-height);
  }


  .home-logo {
    width: 25px;
    height: 25px;
  }

  .home-company {
    font-size: 15px;
    /* display: none; */
  }

  .home-branding.responsive .home-logo {
    width: 25px;
    height: 25px;
  }

  .home-branding.responsive .home-company {
    font-size: 1rem;
  }

  .nav-menu .mobile-links-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-top: 1rem;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-bottom: 14rem;
    overflow-y: scroll;
    height: calc(100dvh - var(--nav-height));
  }

  .profile-pic {
    width: 2.6rem;
    height: 2.6rem;
  }
}