@import url("./base.css");

.stripe-payment-intent-modal .modal-content {
  min-width: 40rem !important;
}

.enterprise-stripe-container {
  padding: 1.5rem;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 10px;
  margin: 1rem;
  margin-top: 2rem;
}

.payment-success-gif {
  width: 15rem;
  display: flex;
  margin: auto;
}

.done-gif {
  width: 7rem;
  display: flex;
  margin: 2rem auto;

}

.payment-success p {
  text-align: center;
  padding: 0 1rem;
}

.floating-subscription-button {
  border-radius: 100%;
  border: none;
  width: 4rem;
  height: 4rem;
  position: fixed;
  bottom: 4dvh;
  right: 4dvw;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
  background-color: var(--color-secondary);
  transition: background-color 150ms linear;
}

.floating-subscription-button:hover {
  background-color: var(--color-secondary-lightened);
}

.floating-subscription-button svg {
  font-size: var(--size-xl);
}

.floating-subscription-button .icon-container {
  position: relative;
}

.floating-subscription-button .icon-container::after {
  content: '';
  position: absolute;
  top: 0;
  right: .8rem;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: rgb(255, 40, 40);
}


@media screen and (max-width: 786px) {
  .stripe-payment-intent-modal .modal-content {
    min-width: 96% !important;
  }

}