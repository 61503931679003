@import url("./base.css");

.shared-indicator-abs-position {
    top: 10px;
    right: 0;
}

.share-modal-body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 1rem 0;
    margin-bottom: 3rem;
    padding: 0 1rem;
}

.share-modal-body h3 {
    font-size: var(--fs-3);
}

.list-dropdown {
    position: absolute;
    width: 100%;
    min-height: 3rem;
    max-height: 10rem;
    overflow-y: scroll;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: var(--color-primary);
    display: flex;
    flex-direction: column;
    padding: 1rem .5rem;
}

.list-item {
    display: flex;
    gap: 1rem;
    padding: .7rem .5rem;
}

.list-item-body {
    display: flex;
    flex-direction: column;
    width: 70%;
}

.list-item-body .name {
    margin: 0;
    font-weight: 500;
    font-size: var(--size-xs) !important;
}

.list-item-body .username {
    font-size: var(--size-xs) !important;
    margin: 0;
}

.access-list-container {}

.access-list {
    max-height: 12rem;
    overflow-y: scroll;
    margin-top: 1rem;

}

.access-list {}

.invite-button {
    height: 2.5rem;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 .5rem;
    padding-right: 1rem;
    background-color: transparent;
    transition: all 100ms linear;
}

.invite-button div {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 100ms linear;
}

.invite-button div:hover {
    background-color: var(--color-lighter);
}

@media screen and (max-width: 786px) {
    .shared-indicator-abs-position {
        top: 10px;
        right: -5px;
        padding: 0;
    }
}