@import url("./base.css");

.tags-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* gap: 1rem; */
    flex-wrap: wrap;
    /* padding: 1rem 0; */
}

.tag-input-container {
    position: relative;
    width: 15rem;
    margin-top: .4rem;
    /* padding: 1.5rem 0; */
}

.tag-input-container.full {
    width: 100%;
}
.tag-input-container.full input{
    padding-right: 3rem;
}

#tag-input {
    /* height: 2.5rem; */
    font-size: 0.9rem;
    padding-right: 2.8rem;
}

.tag-input-container button {
    border: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.tag-input-container button svg {
    font-size: var(--size-m);
}

.selected-tag-container {
    display: flex;
    gap: 1rem;
    padding: 1rem 0;
    flex-wrap: wrap;
}

.tag {
    background-color: rgb(233, 233, 233);
    padding-left: 1rem;
    border-radius: 30px;
    padding: 0rem 0 0rem 1rem !important;
    font-size: var(--size-xxs) !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 2.5rem !important;
    padding: 0 var(--size-s) !important;
    transition: all 100ms linear;
}

.tag:hover {
    /* text-decoration: underline;
    cursor: pointer; */
}

.tag-form-container {
    display: flex;
    gap: .5rem;
}

/* .tag button {
    display: none;
} */

.tag.editing {
    padding: 0rem 0 0rem 1rem !important;
}

.tag.editing:hover {
    text-decoration: none;
    cursor: auto;
}

/* .tag.editing button {
    display: block;

} */

.tag-color-1 {
    background-color: rgb(252, 234, 217) !important;
    color: rgb(105, 68, 34) !important;
}

.tag-color-2 {
    background-color: rgb(236, 252, 217) !important;
    color: rgb(52, 82, 16) !important;

}

.tag-color-3 {
    background-color: rgb(217, 252, 249) !important;
    color: rgb(16, 75, 70) !important;

}

.tag-color-4 {
    background-color: rgb(235, 217, 252) !important;
    color: rgb(88, 43, 129) !important;
}

.tag-color-danger {
    background-color: rgba(255, 0, 0, 0.253);
}

.noTag-addBtn {
    width: auto;
    min-width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    border-radius: 20px;
    text-decoration: underline;
    font-size: 0.9rem;
}

.edit-btn.noTag-addBtn {
    /* min-width: 13rem */
}

.tag-icon {
    font-size: 1.5rem;
    color: rgb(83, 83, 83);
}