@import url("./base.css");

.user-table-row {
    transition: all 100ms linear;
}

.disabled-account-row {
    background-color: rgba(255, 0, 0, 0.171);
}

.enterprise-not-active{
    
}

.token-button svg{
    width: var(--size-m) !important;
    height: var(--size-m) !important;
}

@media screen and (max-width: 425px) {}